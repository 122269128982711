import React from 'react';
import { withRouter, Link} from 'react-router-dom';
import '../styles/scss/Footer.scss';

class Footer extends React.Component {
  render(){
    return (
      <div className='footer'>
        <div className='footer-legal-container'>
          <Link to='/privacypolicy' className='footer-privacy footer-legal'>
            Privacy
          </Link>
          <Link to='/termsofservice' className='footer-terms footer-legal'>
            Terms
          </Link>
        </div>
        Fun Stuff 2 Do
      </div>
    )
  }
}

export default withRouter(Footer);