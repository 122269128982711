import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import '../styles/scss/Place.scss';

const MAPBOX_API_KEY = process.env.REACT_APP_MODE === 'development' ?
  process.env.REACT_APP_MAPBOX_PRIVATE_API_KEY :
  process.env.REACT_APP_MAPBOX_PUBLIC_API_KEY

  class Place extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      data: {
        image_urls: [],
        map_image: '#',
        categories: [],
        website_url: null,
      },
    };

    window.scrollTo(0,0);
  };

  componentDidMount() {
    this.getSuggestion();
  };

  getSuggestion = () => {
    axios.post('/search/place', {
      placeID: this.props.match.params.placeID
    })
    .then(response => {
      response.data.map(row => {
        if (row.image_urls !== null) {
          row.image_urls = Object.values(JSON.parse(row.image_urls));
        } else {
          row.image_urls = [];
        };
        if (row.categories !== null) {
          row.categories = row.categories.split(',');
        } else {
          row.categories = [];
        };
        return true;
      });
      this.setState({
        data: {
          ...response.data[0],
          map_image: `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/pin-s+126486(${response.data[0].longitude},${response.data[0].latitude})/${response.data[0].longitude},${response.data[0].latitude},14,0/1280x350@2x?access_token=${MAPBOX_API_KEY}`,
        }
      });
      if (this.props.match.params.placeID in this.props.userLikes) {
        this.updateIconColor('like', true);
      }
      if (this.props.match.params.placeID in this.props.userStars) {
        this.updateIconColor('star', true);
      };
    })
    .catch(error => {
      console.log(error);
    });
  };

  getDirections = () => {
    /* if we're on iOS, open in Apple Maps */
    if((navigator.platform.indexOf("iPhone") !== -1) ||
      (navigator.platform.indexOf("iPad") !== -1) ||
      (navigator.platform.indexOf("iPod") !== -1))
      window.open(`maps://maps.google.com/maps/dir/?daddr=${this.state.data.address}&amp;ll=`);
    else /* else use Google */
      window.open(`https://maps.google.com/maps/dir/?daddr=${this.state.data.address}&amp;ll=`);
  }

  updateIconColor = (icon, bool) => {
    let idSelector = `${icon}-${this.props.match.params.placeID }`
    // if button isn't present -> ignore updating color
    if (!document.getElementById(idSelector)) return

    if (bool) {
      document.getElementById(idSelector).classList.add(`${icon}-active`);
    } else {
      document.getElementById(idSelector).classList.remove(`${icon}-active`);
    };
  };
  updateLikeIconCount = (bool) => {
    if (bool) {
      this.setState( prevState => {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            count_likes: prevState.data.count_likes + 1
          }
        }
      })
    } else {
      this.setState(prevState => {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            count_likes: prevState.data.count_likes - 1
          }
        }
      })
    };
  };

  liked = () => {
    axios.post('/auth/like', {
      postID: this.props.match.params.placeID
    })
    .then(response => {
      if (response.data.loggedIn){
        this.updateIconColor('like', response.data.likes)
        this.updateLikeIconCount(response.data.likes);
      } else {
        this.props.history.push('/login');
      };
    })
    .catch(error => {
      console.log(error);
    });
  };

  starred = () => {
    axios.post('/auth/star', {
      postID: this.props.match.params.placeID
    })
    .then(response => {
      if (response.data.loggedIn){
        this.updateIconColor('star', response.data.stars)
      } else {
        this.props.history.push('/login');
      };
    })
    .catch(error => {
      console.log(error);
    });
  }

  flagged = () => {
    // console.log('flagged');
  }

  render(){
    return (
      <div>
        <Helmet>
          <title>{this.props.match.params.placeName} - Fun Stuff 2 Do</title>
          <meta name='description' content={this.state.data.suggestion}/>
        </Helmet>
        
        <div className='place-header'>
          <img className='place-map' src={this.state.data.map_image} alt='map'/> 
          <div className='place-center-title'>{this.state.data.place}</div>
        </div>

        <div className = 'place-center'>
          
          <div className='place-categories'>
            {this.state.data.categories.map(category => {
              return <span key={category} className='place-category'>{category}</span>
            })}
          </div>
          <div className='place-center-subheader'>
            <div>
              {this.state.data.website_url ? 
                <Link 
                to={this.state.data.website_url} 
                target='_blank'
                onClick={(event) => {event.preventDefault(); window.open(this.state.data.website_url);}}>
                  <button className='place-button-website'>
                    <i className="fas fa-external-link-alt"></i> Website
                  </button>
                </Link> : 
                ""
              }
            </div>
            <div>
              <button className='place-direction' onClick={() => this.getDirections()}>
                <i className="fas fa-directions"></i> Directions
              </button>
            </div>
          </div>

          <div className='place-clicked-suggestion-box shadow'>
            <div className='place-image-box'> 
              {this.state.data.image_urls.map((image, index) => {
                return <img key={index} className='place-img' src={image} alt="" />
              })}
            </div>

            <div className='place-clicked-suggestion'>
              <div className='place-clicked-suggestion-title'>
                <Link to={`/user/${this.state.data.username}`} className='place-clicked-suggestion-title'>
                <span className='place-clicked-userimage-box'>
                  <img className='place-clicked-userimage shadow' src={this.state.data.user_image_url} alt='userimage'/>
                </span>
                <span className='place-clicked-username'>
                  {this.state.data.first_name}'s Suggestion
                </span>
                </Link>
                <hr className='place-hr'/>
              </div>

              <div className='place-clicked-suggestion-description'>
                {this.state.data.suggestion}
              </div>

              <div className='place-clicked-suggestion-footer'>
                <div className=' place-clicked-suggestion-footer-icons' onClick={this.liked}>
                  {this.state.data.count_likes} <i id={`like-${this.state.data.post_id}`} className="fas fa-thumbs-up"></i>
                </div>
                <i id={`star-${this.state.data.post_id}`} className="fas fa-star place-clicked-suggestion-footer-icons" onClick={this.starred}></i>
                <i className="fas fa-flag place-clicked-suggestion-footer-icons" onClick={this.flagged}></i>
                <hr className='place-clicked-suggestion-footer-hr'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  };
};

const mapStateToProps = state => {
  return {
    userLikes: state.userReducer.user.userLikes,
    userStars: state.userReducer.user.userStars,
  };
};

export default withRouter(connect(mapStateToProps)(Place));