import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../styles/scss/SearchBar.scss';
import { setUserLocation, setSearchLocation } from "../redux/actions/mapActions";
import MapBoxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import PulseLoader from 'react-spinners/PulseLoader';


class SearchBar extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      loading: false,
    };
  };

  componentDidMount(){
    const Geocoder = this.initGeocoder();

    Geocoder.addTo('#geocoder');
    Geocoder.on('result', (ev) => {
      this.props.setSearchLocation(ev.result.center[1], ev.result.center[0], ev.result.text);
      this.props.history.push(`/search?lat=${ev.result.center[1]}&lng=${ev.result.center[0]}`);
    });
  };

  initGeocoder = () => {
    return this.geocoder();
  }

  geocoder = () => {
    const Geocoder = new MapBoxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false,
      placeholder: 'Search Location',
      types: 'region,place,postcode,locality,neighborhood'
    });
    return Geocoder;
  }

  findMe = () => {
    this.setLoading(true);
    navigator.geolocation.getCurrentPosition(position => {
      this.props.setSearchLocation(position.coords.latitude, position.coords.longitude, 'Me');
      this.setLoading(false);
      if (this.props.map){
        this.props.map.flyTo({
          center: [position.coords.longitude, position.coords.latitude],
          zoom: 10,
          essential: true // this animation is considered essential with respect to prefers-reduced-motion
        });
      };
      this.props.history.push(`/search?lat=${position.coords.latitude}&lng=${position.coords.longitude}`);
    }, error => {
      this.setLoading(false);
      console.log(error);
    });
  };
  setLoading = (loading) => {
    this.setState({
      loading,
    });
  };

  render(){
    let searchBarShadow = window.location.pathname === '/' ? '' : 'shadow-nav-btns';
    return (
      <section  className={`searchBar ${searchBarShadow}`}>
        <div id='geocoder' className='geocoder'/>
        <div id='findMe' onClick={this.findMe}>
          <button className={`searchBar-btn`} type="button">
            <div>
              { this.state.loading === false ? 
              <span>
                <i className="fas fa-map-marker-alt"></i> {this.props.searchBarButtonText}
              </span> :
              <PulseLoader size={5} color={"white"}/>
            }
            </div>
          </button>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => {
  return {
    map: state.mapReducer.map,
  };
};

const mapDispatchToProps = {
  setUserLocation,
  setSearchLocation
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchBar));