import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import '../styles/scss/Account.scss';

class Account extends React.Component {
  constructor(props){
    super(props);
    window.scrollTo(0, 0);
  }

  render(){
    return (
      <div className='account'>
        <div className='account-header'>
          <div className='account-left-side'>
            <div className='account-detail-box shadow'>
              <Link to='/account/details'><div className='account-detail-list'>Account Details</div></Link>
              <Link to='/account/posts'><div className='account-detail-list'>Edit Posts</div></Link>
              <Link to='/account/delete'><div className='account-detail-list'>Delete Account</div></Link>
              <a href='/auth/logout'><div className='account-detail-list'>Logout</div></a>
            </div>
          </div>

          <div className='account-right-side'>
            <div className='account-detail-header'>
              <img className='account-userImg shadow' src={this.props.user.imageURL} alt='userImage'/>
              <div className='account-userName-box'>
                <div className='account-userName'>
                  Hey, {this.props.user.firstName} {this.props.user.lastName}!
                </div>
                <hr className='account-hr'/>
                <p>
                  Here are your account details...
                </p>
                <Link to={`/user/${this.props.user.username}`}>
                  <button className='account-public-profile-btn shadow'>View Public Profile</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user
  };
};

export default withRouter(connect(mapStateToProps)(Account));