export const setUserLocation = (lat, lng) => {
  return {
    type: "SET_USER_LOCATION",
    lng,
    lat
  };
};

export const setSearchLocation = (lat, lng, place) => {
  return {
    type: "SET_SEARCH_LOCATION",
    lng,
    lat,
    place
  };
};

export const setMap = (map) => {
  return {
    type: "SET_MAP",
    map
  }
}