const INITIAL_STATE = {
  all: {
    'Bakery':               true,
    "Bubble Tea":           true,
    "Burrito":              true,
    "Cafe":                 true, 
    "Coffee":               true, 
    "Ferry":                true,
    "Hike":                 true,
    "Hiking":               true,
    "Hiking Trailhead":     true,
    "Trailhead":            true,
    "Historic":             true,
    "Historic Site":        true,
    "Japanese Food":        true,
    "Japanese Restaurant":  true,
    "Leisure":              true,
    "Marina":               true,
    "Museum":               true,
    "Mexican Food":         true,
    "Mexican Restaurant":   true,
    "National Park":        true,
    "Natural Park":         true,
    "Port":                 true,
    "Restaurant":           true,
    "Seafood":              true,
    "Seafood Restaurant":   true,
    "Shop":                 true,
    "Sporting":             true,
    "Sporting Good":        true,
    "Sporting Goods":       true,
    "Sports Store":         true,
    "Tea":                  true,
    "Tea House":            true,
    "Tourism":              true,
  },
  categories: {
    drinks: ['Bubble Tea', 'Coffee', 'Tea'],
    food: ['Bakery', 'Burrito', 'Japanese Food', 'Mexican Food', 'Seafood'],
    landmarks: ['Hiking', 'Historic Site', 'Leisure', 'Museum', 'National Park', 'Tourism'],
    shopping: ['Sporting Goods']
  },
  other: [],
  filters: {}
}

const filterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_CATEGORIES": {
      return {
        ...state,
        other: action.categories.filter(category => category in state.all === false)
      };
    }
    case "ADD_FILTER": {
      return {
        ...state,
        filters: {
          [action.filter]: true
        }
      };
    }
    case "REMOVE_FILTER": {
      return {
        ...state,
        filters: Object.keys(state.filters).reduce((result, key) => {
          if (key !== action.filter){
            result[key] = state.filters[key];
          }
          return result;
        }, {})
      };
    }
    case "ADD_FILTERS": {
      let newObject = {};
      action.filters.forEach(filter => {
        newObject[filter] = true;
      });
      return {
        ...state,
        filters: {
          ...newObject
        }
      }
    }
    case "CLEAR_FILTERS": {
      return {
        ...state,
        filters: {}
      }
    }
    default:
      return state;
  }
};

export default filterReducer;
