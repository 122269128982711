import React from 'react';
import axios from 'axios';
import isUrl from 'is-url';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { connect } from 'react-redux';
import { setAllPosts, clearPost } from "../../redux/actions/userActions";
import '../../styles/scss/ConfirmationPage.scss';

const MAPBOX_API_KEY = process.env.REACT_APP_MODE === 'development' ?
  process.env.REACT_APP_MAPBOX_PRIVATE_API_KEY :
  process.env.REACT_APP_MAPBOX_PUBLIC_API_KEY

class ConfirmationPage extends React.Component{
  constructor(props) {
    super(props);

    let website_url;
    if (this.props.post.website_url){
      website_url = this.props.post.website_url;
    } else {
      website_url = '';
    };

    this.state = {
      ...this.props.post,
      newImage: [],
      newImageURLs: [],
      newImages: false,
      website_url,
      loading: false,
    };
  }
  componentWillUnmount(){
    this.cancel();
  };

  updateSuggestion = (event) => {
    this.setState({
      ...this.state,
      suggestion: event.target.value
    })
  }

  cancel = () => {
    this.props.clearPost();
  }
  save = () => {
    // website url must be valid OR empty
    if (!this.isWebsiteURLValid(this.state.website_url)){
      return
    }

    // dont post if no images or suggestion exists
    if ((this.state.image_urls.length + this.state.newImage.length <= 0) || this.state.suggestion.length <= 0){
      return;
    };

    this.setState({
      loading: true,
    });
    if (this.state.newImage.length > 0){
      this.getPresignedURLs(this.state.newImage.length);
    } else {
      this.postContent([]);
    }
  }
  isWebsiteURLValid = (url) => {
    if (isUrl(url) || url === '') {
      return true
    } 
    document.getElementById('website-url-invalid').style.display = 'block';
    return false
  }
  delete = () => {
    let payload = {
      post_id: this.state.post_id
    };
    axios.delete('/auth/post', {data: payload})
    .then(response => {
      this.done();
    })
    .catch(error => {
      console.log(error);
    });
  };
  
  getPresignedURLs = (numberOfNewImages) => {
    axios.post('/image/url', {
      place: this.state.place, 
      length: numberOfNewImages
    })
    .then(response => {
      // upload to s3
      this.uploadImages(response.data.preSignedURLs);
    })
    .catch(error => {
      console.log(error)
    });
  };
  uploadImages = (preSignedURLs) => {
    let options = {
      headers: {
        'Content-Type': 'image/*'
      }
    }
    const numberOfImages = preSignedURLs.length;
    let imagesUploaded = 0;
    let imageURLs = new Array(numberOfImages).fill(false);    
    
    for (let i = 0; i < numberOfImages; i++){
      axios.put(preSignedURLs[i], this.state.newImage[i], options)
      // eslint-disable-next-line 
      .then(response => {
        imageURLs[i] = preSignedURLs[i].split('?')[0];
        imagesUploaded += 1;
        // let progressProgression = (imagesUploaded  / numberOfImages) * 100;
        // this.updateProgressBar(progressProgression);

        if (imagesUploaded === numberOfImages){
          this.postContent(imageURLs);
        }
      })
      .catch(error => {
        console.log('error posting image');
        console.log(error);
      });
    };
  };
  postContent = (imageURLs) => {
    let payload = {
      imageURLs: [...this.state.image_urls, ...imageURLs],
      suggestion: this.state.suggestion,
      newImages: this.state.newImages,
      postID: this.state.post_id,
      website_url: this.state.website_url,
    }
    axios.put('/auth/post', payload)
    .then(response => {
      this.done();
    })
    .catch(error => {
      console.log(error);
    });
  };
  done = () => {
    this.getAllPosts();
    this.props.clearPost();
    window.scrollTo(0,0);
  };
  getAllPosts = () => {
    axios.get('/auth/posts')
    .then(response => {
      response.data.map(row => {
        if (row.image_urls !== null) {
          row.image_urls = Object.values(JSON.parse(row.image_urls));
        } else {
          row.image_urls = [];
        };
        if (row.categories !== null) {
          row.categories = row.categories.split(',');
        } else {
          row.categories = [];
        };
        return true;
      });

      this.props.setAllPosts(response.data);
    })
    .catch(error => {
      console.log(error);
    })
  }

  addImage = event => {
    Object.values(event.target.files).forEach(image => {
      let imageURL = URL.createObjectURL(image);

      this.setState( prevState => ({
        newImage: [...prevState.newImage, image],
        newImageURLs: [...prevState.newImageURLs, imageURL],
        newImages: true
      }));
    });
  };
  removeImage = (index, local) => {
    if (local){
      this.removeLocalImage(index);
    } else {
      this.removeHostedImage(index);
    };
  };
  removeLocalImage = (index) => {
    this.setState(prevState => ({
      newImage: prevState.newImage.filter((_, i) => i !== index),
      newImageURLs: prevState.newImageURLs.filter((_, i) => i !== index),
      newImages: true
    }));
  };
  removeHostedImage = (index) => {
    this.setState(prevState => ({
      image_urls: prevState.image_urls.filter((_, i) => i !== index),
      newImages: true
    }));
  };
  updateWebsiteURL = (event) => {
    this.setState({
      website_url: event.target.value
    });
  };

  render(){
    return (
      <div className='confirmation-page shadow'>
        <div className='confirmation-page-location'>
          <img className='confirmation-page-map' src={`https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/pin-s+126486(${this.state.longitude},${this.state.latitude})/${this.state.longitude},${this.state.latitude},14,0/1280x300@2x?access_token=${MAPBOX_API_KEY}`} alt='map'/> 
          <div className='confirmation-page-place'>{this.state.place}</div> 
        </div>

        <div className='confirmation-page-body'>
          <div className='confirmation-page-categories'>
            {this.state.categories.map(category => {
              return <span key={category} className='confirmation-page-category'>{category}</span>
            })}
          </div>

          {this.props.action === 'Edit' ? 
            <div className='confirmation-page-website'>
              <span className='confirmation-page-website-name'>
                <i className="fas fa-external-link-alt"></i> Website
              </span>
              <input 
                className='confirmation-page-website-edit'
                type="text"
                placeholder="https://www.example.com"
                value={this.state.website_url}
                onChange={this.updateWebsiteURL}>
              </input>
            </div> :
            ""
          }
          {this.props.action === 'Edit' &&
            <div id='website-url-invalid' className='confirmation-page-website-invalid'>
              * URL is invalid
            </div>
          }

          {this.props.action === 'Edit'?
            <div className='confirmation-page-images'>
              {this.state.image_urls.map((image, index) => {
                return <div key={image} className='confirmation-page-image-overlay'><img className='confirmation-page-image' src={image} onClick={() => this.removeImage(index, false)} alt='place'/></div>
              })}
              {this.state.newImageURLs.map((image, index) => {
                return <div key={image} className='confirmation-page-image-overlay'><img className='confirmation-page-image' src={image} onClick={() => this.removeImage(index, true)} alt='place'/></div>
              })}
            </div>:
            <div className='confirmation-page-images no-border'>
              {this.state.image_urls.map(image => {
                return <img key={image} className='confirmation-page-image-overlay' src={image} alt='place'/>
              })}
            </div>
          }

          {this.props.action === 'Edit' && 
            <div className='confirmation-page-edit-images'>
              <i className="fas fa-exclamation-circle"></i> Click on Image to Delete 

              <div className='confirmation-page-add-image'>
                <input id='file' type='file' accept='image/*' onChange={this.addImage} multiple/>
                <label htmlFor='file' className='confirmation-page-add-image-btn shadow'> <i className="fas fa-upload"></i> Add Image </label>
              </div>
            </div>
          }

          

          <div className='confirmation-page-content'>
            <div className='confirmation-page-suggestion'>
              <div className='confirmation-page-suggestion-title'>Suggestion</div>
              {this.props.action === 'Edit' ? 
                <textarea className="confirmation-page-suggestion-textarea" data-gramm_editor="false" value={this.state.suggestion} onChange={this.updateSuggestion}/>:
                <div className='confirmation-page-suggestion-box'>
                  <hr className='confirmation-page-hr'/>
                  {this.state.suggestion}
                </div>}
            </div>
          </div>
          
          {this.props.action === 'Delete' && 
            <div className='confirmation-page-delete-warning'>
              <i className="fas fa-exclamation"></i> 
              Are you sure you want to delete?
              <br/>
              Deletions are permanent!
            </div>
          }

          {this.state.loading &&
          <div className='confirmation-page-loading'>
            <PropagateLoader
              color={"#333335"}
              />
          </div>}
          
          <div className='confirmation-page-btns'>
            <button className='confirmation-page-cancel-btn shadow' onClick={this.cancel}>Cancel</button>
            {this.props.action === 'Edit'?
              <button className='confirmation-page-save-btn' onClick={this.save}>Save</button>:
              <button className='confirmation-page-save-btn' onClick={this.delete}><i className="fas fa-trash"></i> Delete</button>
            }
            
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    post: state.userReducer.posts.post,
    action: state.userReducer.posts.action
  };
};

const mapDispatchToProps = {
  clearPost,
  setAllPosts,
};
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage);