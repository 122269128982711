import React from 'react';
import { withRouter, Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { loginUser, logoutUser } from '../redux/actions/userActions';
import { setCategories } from '../redux/actions/filterActions';
import mapboxgl from 'mapbox-gl';
import SearchBar from './SearchBar';
import axios from 'axios';
import '../styles/scss/Navbar.scss';
import { Helmet } from 'react-helmet';

process.env.REACT_APP_MODE === 'development' ?
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PRIVATE_API_KEY :
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_API_KEY

class Navbar extends React.Component{
  componentDidMount(){
    axios.get('/auth/login')
      .then(response => {
        let userData = response.data
        if(userData){
          let user_likes      = {};
          let user_stars      = {};
          let user_followees  = {};

          if(userData.user_likes) {
            user_likes = userData.user_likes.split(',').reduce((key, value) => {
                key[value] = true;
                return key;
              }, {});
          }
          if (userData.user_stars){
            user_stars = userData.user_stars.split(',').reduce((key, value) => {
                key[value] = true;
                return key;
              }, {});
          }
          if (userData.user_followees){
            user_followees = userData.user_followees.split(',').reduce((key, value) => {
                key[value] = true;
                return key;
              }, {});
          }
          
          this.props.loginUser(
            userData.user_id, 
            userData.first_name, 
            userData.last_name, 
            userData.image_url, 
            userData.email, 
            userData.user_since.split('T')[0], 
            user_likes,
            user_stars,
            user_followees,
            true,
            userData.username);
        }
      })
      .catch(error => {
        console.log(error)
      });
    axios.get('/categories')
      .then(response => {
        if (response.data.categories){
          let categoriesArray = response.data.categories.map(categoryObject => categoryObject.category);
          this.props.setCategories(categoriesArray);
        }
      })
  }
  showAccountDropdown = () => {
    document.getElementById('nav-btn-user').style.borderBottomRightRadius = '0';
    document.getElementById('nav-btn-user').style.borderBottomLeftRadius = '0';
    document.getElementById('nav-user-account').style.display = 'block';
  };
  hideAccountDropdown = () => {
    document.getElementById('nav-btn-user').style.borderBottomRightRadius = '1em';
    document.getElementById('nav-btn-user').style.borderBottomLeftRadius = '1em';
    document.getElementById('nav-user-account').style.display = 'none';
  };
  accountDropdown = bool => {
    bool ?
    this.showAccountDropdown():
    this.hideAccountDropdown();
  };

  isAuthenticated = (route) => {
    // must be logged in before being able to post
    this.props.connected ? 
    this.props.history.push(route):
    this.props.history.push('/login');
  };
  uncheck = () => {
    let checkbox = document.getElementById('hamburgerCheckbox');
    if (checkbox)
      checkbox.checked = false; 
  }

  render() {
    return (
      <header className='nav-header'>
        <Helmet>
          <title>Find New, Unique, Fun Things To Do - Fun Stuff 2 Do</title>
          <meta
            name="description"
            content="Find new, exciting, fun stuff to do recommended by other people. Simply enter a location and start exploring!"
            data-react-helmet="true"
          />
        </Helmet>
        
        <Link className='logo med-font' to='/'>FUN STUFF 2 DO</Link>

        {this.props.location.pathname !== '/' && 
          <div className='nav-search-mobile'>
            <SearchBar/>
          </div>
        }

        <div id="menuToggleMobile">
          <input id='hamburgerCheckbox' type="checkbox" onFocus={this.uncheck()}/>
          <span></span>
          <span></span>
          <span></span>

          <ul id="menu">
            <button className='nav-btn-mobile' type='button' onClick={() => this.isAuthenticated('/post')}>
              <li className='nav-btn-mobile-text'> <i className="fas fa-clipboard"/> Post</li>
            </button>
            <button className='nav-btn-mobile' type='button' onClick={() => this.isAuthenticated(`/user/${this.props.user.userId}/starred`)}>
              <li className='nav-btn-mobile-text'> <i className="fas fa-star"></i> Starred</li>
              </button>
            <button className='nav-btn-mobile' type='button' onClick={() => this.isAuthenticated(`/following`)}>
              <li className='nav-btn-mobile-text'> <i className="fas fa-plus"></i> Following</li>
            </button>

            {this.props.connected ?
              <ul className='nav-btn-mobile-loggedIn'>
                <button className='nav-btn-mobile' type='button' onClick={() => this.isAuthenticated(`/account/details`)}>
                  <li className='nav-btn-mobile-text nav-btn-mobile-user'>                   
                    <img className='nav-btn-user-image' src={this.props.user.imageURL} alt="user-img"></img> 
                    Account
                  </li>
                </button>
                <a href='/auth/logout'>
                  <button className='nav-btn-mobile' type='button'>
                    <li className='nav-btn-mobile-text'>Logout</li>
                  </button>
                </a>
              </ul>
            :
              <button className='nav-btn-mobile' type='button' onClick={() => this.isAuthenticated(`/login`)}>
                <li className='nav-btn-mobile-text'> <i className="fas fa-user"></i> Login</li>
              </button>
            }
          </ul>
        </div>
        
        <div id='menuToggleDesktop' className='nav-right'> 
          <button className='nav-btn shadow-nav-btns' type="button" onClick={() => this.isAuthenticated('/post')}>
            <i className="fas fa-clipboard"/> Post
          </button>

          <button className='nav-btn shadow-nav-btns' type="button" onClick={() => this.isAuthenticated(`/user/${this.props.user.username}/starred`)}>
            <i className="fas fa-star"></i> Starred
          </button>

          <button className='nav-btn shadow-nav-btns' type="button" onClick={() => this.isAuthenticated(`/following`)}>
            <i className="fas fa-plus"></i> Following
          </button>

          {
            this.props.connected
            ? 
              <div className='nav-user-btn'>
                <button id='nav-btn-user' className='nav-btn nav-user-btn mr-0 shadow-nav-btns' type="button" 
                  onClick={() => this.accountDropdown(true)}
                  onMouseLeave={() => this.accountDropdown(false)}>    
                  <img className='nav-user-img' src={this.props.user.imageURL} alt="user-img"></img> 
                  <div className='nav-user-text'>
                    {this.props.user.firstName}!
                  </div>   
                </button>
                <div id='nav-user-account' className='nav-user-account'
                  onMouseEnter={() => this.accountDropdown(true)}
                  onMouseLeave={() => this.accountDropdown(false)}>
                  <Link to='/account/details'>
                    <div className='nav-user-account-style-account'>Account</div>
                  </Link>
                  <a href='/auth/logout'><div className='nav-user-account-style-logout'>Logout</div></a>
                </div>
              </div>
            :
            <Link to='/login'>
              <button className='nav-btn mr-0 shadow-nav-btns' type="button">    
                <i className="fas fa-user"></i> Login           
              </button>
            </Link>
          }
        </div>

      </header>
    );
  };
};

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    connected: state.userReducer.connected,
  };
};
const mapDispatchToProps = {
  loginUser,
  logoutUser,
  setCategories,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));