import React from 'react';
import { connect } from 'react-redux';
import MapBoxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import { setPlaceAutoSuggest, setPlaceNameManually, setPlaceAddressManually, clearPlace } from "../../redux/actions/postActions";

class PostStep1 extends React.Component{

  componentDidMount() {
    this.props.clearPlace(); // reseting place also resets categories

    let poi = new MapBoxGeocoder({
        accessToken: mapboxgl.accessToken,
        marker: false,
        placeholder: 'Search Place',
        types: 'poi'
      });
    let address = new MapBoxGeocoder({
        accessToken: mapboxgl.accessToken,
        marker: false,
        placeholder: 'Enter Address',
        types: 'address'
      });

    poi.addTo('#auto-suggest');
    address.addTo('#address');

    poi.on('result', (ev) => {
      const result = ev.result;
      let categories;

      try {
        categories = this.updateCategories(result.properties.category);
      } catch (error) {
        categories = [];
      }
      this.props.setPlaceAutoSuggest(result.text, result.place_name, result.center[1], result.center[0], categories);
    });

    // For Manual Input: 
    //    'result'  -> autocomplete address with user clicked suggestion
    //    'results' -> autocomplete address with first autosuggestion
    address.on('result', (ev) => {
      const result = ev.result;
      this.setState({
        ...this.state,
        address: result.place_name,
        latitude: result.center[1],
        longitude: result.center[0]
      });

      this.props.setPlaceAddressManually(result.place_name, result.center[1], result.center[0]);
    });
    address.on('results', ev => {
      const result = ev.features[0];
      if (result){
        this.setState({
          ...this.state, 
          address:    result.place_name,
          latitude:   result.center[1],
          longitude:  result.center[0],
        });
      };
    });
  };

  updateCategories = (categories) => {
    let uppercasedCategories = [];

    categories = categories.split(', ');
    categories.forEach(category => {
      category = category.split(' ');
      let uppercasedWord = category.map(word => {
        return word[0].toUpperCase() + word.substr(1);
      });
      uppercasedCategories.push(uppercasedWord.join(' '));
    })
    
    return uppercasedCategories;
  };

  updatePlace = (event) => {
    let place = this.uppercase(event.target.value);
    this.props.setPlaceNameManually(place);
  }
  uppercase = (words) => {
    let arrayOfWords = words.split(' ');
    let uppercaseWords = arrayOfWords.map(word => {
      if (word){
        return word[0].toUpperCase() + word.slice(1);
      }
      return word
    });
    return uppercaseWords.join(" ");
  }
  render(){
    return (
      <div className="post-form-steps">
        <div id='step-1' className='post-form-step-header'>
          Step <span id='step-1-bg' className='post-form-step-number'>1</span>: <span className='post-form-step-description'>Enter Place</span>
        </div>
        
        <div className='post-form-section'>
          <div className='post-form-left-side text-right'>
            Using Auto-Suggest <i className="fas fa-arrow-alt-circle-right"></i>
          </div>
          <div className='post-form-right-side'>
              <div className='post-form-auto-suggest-label'>Search Place by Name:</div>
              <div id='auto-suggest'></div>
          </div>
        </div>
        

        <div className='post-form-section'>
          <div className='post-form-left-side left-side-none'>
          </div>
          <div className='post-form-right-side'>
              <div className='post-form-or'>OR</div>
          </div>
        </div>
        
        <div className='post-form-section'>
          <div className='post-form-left-side text-right'>
            Manually <i className="fas fa-arrow-alt-circle-right"></i>
          </div>
          <div className='post-form-right-side'>
              <div className='post-form-auto-suggest-label'>Enter Name of Place:</div>
              <input className='post-form-place' type='search' placeholder='Enter Place' value={this.props.place} onChange={this.updatePlace}></input>
          </div>
        </div>

        <div className='post-form-section'>
          <div className='post-form-left-side text-right left-side-none'>
          </div>
          <div className='post-form-right-side'>
              <div className='post-form-auto-suggest-label'>Enter Address of Place:</div>
              <form autoComplete='off'>
                <div id='address'></div>
              </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    place: state.postReducer.place,
    userLocation: {
      lng: state.mapReducer.userLocation.lng,
      lat: state.mapReducer.userLocation.lat
    }
  };
};

const mapDispatchToProps = {
  clearPlace,
  setPlaceAutoSuggest,
  setPlaceNameManually, 
  setPlaceAddressManually
}

export default connect(mapStateToProps, mapDispatchToProps)(PostStep1);