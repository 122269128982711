const INITIAL_STATE = {
  images: [],
  imageURLs: [],
  place: '',
  address: '',
  latitude: '',
  longitude: '',
  categories: [],
  suggestion: '',
  websiteURL: '',
  preview: false
}

const postReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_POST": {
      return {
        images: action.post.images,
        place: action.post.place,
        suggestion: action.post.suggestion,
        preview: action.preview
      };
    }
    case "CLEAR_POST": {
      return {
        images: [],
        imageURLs: [],
        place: '',
        address: '',
        latitude: '',
        longitude: '',
        categories: [],
        suggestion: '',
        websiteURL: '',
        preview: false
      };
    }
    case "GO_BACK": {
      return {
        ...state,
        preview: false
      };
    }
    case "SET_PLACE_AUTO_SUGGEST": {
      return {
        ...state,
        place: action.place, 
        address: action.address,
        latitude: action.latitude,
        longitude: action.longitude, 
        categories: action.categories,
      };
    }
    case "SET_PLACE_NAME_MANUALLY": {
      return {
        ...state,
        place: action.place, 
      };
    }
    case "SET_PLACE_ADDRESS_MANUALLY": {
      return {
        ...state,
        address: action.address, 
        latitude: action.latitude, 
        longitude: action.longitude
      };
    }
    case "ADD_IMAGE": {
      return {
        ...state,
        images: [...state.images, action.image],
        imageURLs: [...state.imageURLs, action.imageURL]
      }
    }
    case "REMOVE_IMAGE":{
      return {
        ...state, 
        images: state.images.filter((_, index) => index !== action.removeIndex),
        imageURLs: state.imageURLs.filter((_, index) => index !== action.removeIndex)
      }
    }
    case "SET_SUGGESTION": {
      return {
        ...state,
        suggestion: action.suggestion
      };
    }
    case "SET_WEBSITE_URL": {
      return {
        ...state, 
        websiteURL: action.websiteURL
      };
    }
    case "CLEAR_PLACE": {
      return {
        ...state,
        place: '',
        address: '',
        latitude: '',
        longitude: '',
        categories: [],
      };
    }
    default:
      return state;
  }
};

export default postReducer;
