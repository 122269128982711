import React from 'react';
import { withRouter, Link} from 'react-router-dom';
import '../styles/scss/Login.scss';

class Login extends React.Component {
  componentDidMount(){
    window.scrollTo(0,0);
    document.title = 'Login - Fun Stuff 2 Do';
  };
  componentWillUnmount(){
    document.title = 'Find New, Unique, Fun Things To Do - Fun Stuff 2 Do';
  };
  render(){
    return (
      <div className='login-box shadow'>
        <div className='login-title'>
          Login
          <hr className='login-hr'></hr>
        </div>
        
        <a href='/auth/facebook'>
          <button className="login-FB">
            <img className='login-F-logo' src={require('../assets/f_logo_RGB-White_58.png')} alt='f-logo'/>
            Continue with Facebook
          </button>
        </a>

        <a href='/auth/google'>
          <button className='login-G'>
            <img className='login-G-logo' src={require('../assets/g-logo.png')} alt='g-logo'/>
            Continue with Google
          </button>   
        </a>

        <hr className='login-hr-splitter'/>
        <section className='login-privacy-terms'>
          <p>Please read our 
            <br/> <Link className='login-privacy-terms-links' to='/privacypolicy'>Privacy Policy</Link> and <Link className='login-privacy-terms-links' to='/termsofservice'>Terms Of Service</Link>.
          </p>
        </section>
        
      </div>
    )
  }
}

export default withRouter(Login);