const INITIAL_STATE = {
  marker: null,
  userLocation: {
    lat: null,
    lng: null,
  },
  latitude: 1,      // default is 1 
  longitude: 1,   // default is 1 
  place: null,
  map: null,
}

const mapReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_USER_LOCATION": {
      return {
        ...state,
        userLocation: {
          lat: action.lat,
          lng: action.lng
        }
      };
    }
    case "SET_SEARCH_LOCATION": {
      return {
        ...state,
        latitude: action.lat,
        longitude: action.lng,
        place: action.place
      };
    }
    case "SET_MAP":{
      return{
        ...state,
        map: action.map
      }
    }
    default:
      return state;
  }
};

export default mapReducer;
