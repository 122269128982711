import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { setMarkers } from "../redux/actions/markerActions";
import { setSearchResults, setFilteredSearchResults } from "../redux/actions/searchActions";
import { followUser, unfollowUser } from "../redux/actions/userActions";
import PropagateLoader from 'react-spinners/PropagateLoader';
import mapboxgl from 'mapbox-gl';
import Card from './Card';
import Filters from './Filters';
import Map from './Map';
import '../styles/scss/PublicProfile.scss';

class PublicProfile extends React.Component{
  constructor(props) {
    super(props);

    let showFilterBar = false
    if (window.innerWidth >= 1100)
      showFilterBar = true

    let path = this.props.location.pathname.split("/");

    this.state = {
      showFilterBar,
      sortStyle: 'Recent',
      firstName: '',
      lastName: '',
      fullName: '',
      imageURL: '#',
      numberOfPosts: 0,
      numberOfFollowers: 0,
      numberOfLikes: 0,
      loading: true,
      starred: path[path.length - 1] === "starred",
    };
  };
  componentDidMount(){
    let username = this.props.match.params.username;
    if (!this.state.starred) 
      this.getUserSuggestions(username, "suggestion");
    else
      this.getUserSuggestions(username, "starred");
  }
  toggleFilterMoveLeftClass = () => {
    this.setState({
      showFilterBar: !this.state.showFilterBar
    });
  };

  getUserSuggestions = (username, endpoint) => {
    axios.post(`/search/user/${endpoint}`, {
      username,
    })
    .then(response => {
      this.setUser(response.data.user[0]);

      let numberOfLikes = 0;
      response.data.results.map(row => {
        numberOfLikes += row.count_likes;
        if (row.image_urls !== null) {
          row.image_urls = Object.values(JSON.parse(row.image_urls));
        } else {
          row.image_urls = [];
        };
        if (row.categories !== null) {
          row.categories = row.categories.split(',');
        } else {
          row.categories = [];
        };
        return true;
      });

      this.centerMap(response.data.results);
      this.props.setSearchResults(response.data.results);
      this.setNumberOf(response.data.results.length, numberOfLikes);
      if (Object.keys(this.props.filters).length === 0) {
        this.props.setFilteredSearchResults(response.data.results);
        this.redrawMarkers(response.data.results);
      } else if ('Other' in this.props.filters) {
        this.getFilteredResults(response.data.results, true);
      } else {
        this.getFilteredResults(response.data.results, false);
      }
      this.loading(false);
    })
    .catch(error => {
      console.log(error);
    });
  };
  centerMap = (results) => {
    if (results.length){
      const latitude  = results[0].latitude
      const longitude = results[0].longitude
      this.props.map.flyTo({
        center: [longitude, latitude]
      });
    }
  }
  setUser = (user) => {
    this.setState({
      firstName: user.first_name,
      lastName: user.last_name,
      fullName: `${user.first_name} ${user.last_name}`,
      imageURL: user.image_url,
      numberOfFollowers: user.user_followers,
    });
  };
  setNumberOf = (posts, likes) => {
    this.setState({
      numberOfPosts: posts,
      numberOfLikes: likes,
    });
  };

  showSortingStyles = () => {
    document.getElementById('sort-styles').style.display = 'block';
  }
  hideSortingStyles = () => {
    document.getElementById('sort-styles').style.display = 'none';
  }
  sortLikes = () => {
    return this.props.filteredSearchResults.sort((a, b) => (a.count_likes < b.count_likes) ? 1 :
      (a.count_likes === b.count_likes) ? ((a.place > b.place) ? 1 : -1) : -1);
  }
  sortDate = () => {
    return this.props.filteredSearchResults.sort((a, b) => (a.date_created < b.date_created) ? 1 : -1);
  }
  sortStuff = (style) => {
    this.hideSortingStyles();
    let sortedData;
    switch (style) {
      case 'Recent':
        sortedData = this.sortDate();
        break;
      case 'Likes':
        sortedData = this.sortLikes();
        break;
      default:
        console.log('Couldnt sort');
    };
    this.setState({
      sortStyle: style
    });
    this.props.setFilteredSearchResults(sortedData);
    this.redrawMarkers(sortedData);
  }

  redrawMarkers = (places) => {
    this.removeAllMarkers();
    this.createNewMarkers(places);
  }
  removeAllMarkers = () => {
    Object.values(this.props.markers).forEach(marker => {
      marker.remove();
    })
  };
  createNewMarkers = (places) => {
    let markers = {};
    // reverse array so that the very first card overlaps the second card
    let placesReversed = places.slice().reverse();
    placesReversed.forEach(place => {
      const marker = new mapboxgl.Marker({
          color: '#126486',
          scale: 0.75
        })
        .setLngLat([place.longitude, place.latitude])
        .setPopup(new mapboxgl.Popup({
          // closeOnMove: true,
          closeButton: false,
          className: 'map-popup'
        }).setHTML(`
          <div>
            <img class='map-popup-img' src="${place.image_urls[0]}"/>
            <div class='map-popup-title'>${place.place}</div>
          </div>
        `)) // add popup
        .addTo(this.props.map);

      markers[place.post_id] = marker;
    });
    this.props.setMarkers(markers);
  };

  loading = (isLoading) => {
    this.setState({
      loading: isLoading
    });
  };

  follow = () => {
    this.props.isConnected ?
    this.followAPI() :
    this.props.history.push('/login');
  }
  followAPI = () => {
    axios.post('/auth/follow', {
      followee_user_id: this.props.match.params.username
    })
    .then(response => {
      if (response.data.loggedIn) {
        this.followUpdate(response.data.following);
      } else {
        this.props.history.push('/login');
      };
    })
    .catch(error => {
      console.log(error);
    })
  }
  followUpdate = (isFollowing) => {
    if (isFollowing){
      this.props.followUser(this.props.match.params.userID);
    } else {
      this.props.unfollowUser(this.props.match.params.userID);
    }
    this.followNumberUpdate(isFollowing);
  };
  followNumberUpdate = (isFollowing) => {
    let counter = isFollowing ? 1 : -1;
    this.setState(prevState => {
      return {
        ...prevState,
        numberOfFollowers: prevState.numberOfFollowers + counter
      };
    });
  };
  likeNumberUpdate = (isLiked) => {
    let counter = isLiked ? 1 : -1;
    this.setState(prevState => {
      return {
        ...prevState,
        numberOfLikes: prevState.numberOfLikes + counter
      };
    });
  };

  render(){
    let searchPageFilterClass = this.state.showFilterBar ? '' : 'search-filters';
    let followButton = '';
    if (this.props.match.params.username !== this.props.username){
      if (this.props.match.params.username in this.props.userFollowees) {
        followButton = <button className='public-profile-follow-btn shadow' onClick={this.follow}><i className="fas fa-minus"></i> Unfollow</button>
      } else {
        followButton = <button className='public-profile-follow-btn shadow' onClick={this.follow}><i className="fas fa-plus"></i> Follow</button>
      }
    }
    return (
      <section className='public-profile'>
        {!this.state.loading && 
        <div className='public-profile-header public-profile-mobile'>
          <img className='public-profile-image shadow' src={this.state.imageURL} alt='userImage'></img>
          <div className='public-profile-name-box'>
            <div className='public-profile-name'>
              {this.state.fullName}

              {followButton}
            </div>
            <hr/>

            {!this.state.starred
            ? 
              <div className='public-profile-subheader'>
                <div className='public-profile-subheader-content'>
                  <div className='public-profile-subheader-content-title med-font'>
                    {this.state.numberOfPosts}
                  </div>
                  Suggestions
                </div>
                <div className='public-profile-subheader-content'>
                  <div className='public-profile-subheader-content-title med-font'>
                    {this.state.numberOfFollowers}
                  </div>
                  Followers
                </div>
                <div className='public-profile-subheader-content'>
                  <div className='public-profile-subheader-content-title med-font'>
                    {this.state.numberOfLikes}
                  </div>
                  Likes
                </div>
              </div>
            : 
              <div className='public-profile-subheader'>
                <div className='public-profile-subheader-content'>
                  <div className='public-profile-subheader-content-title med-font'>
                    <i className="fas fa-star star-active"></i>
                  </div>
                  Suggestions
                </div> 
              </div>
            }   
          </div>
        </div>}

        <div className='public-profile-body'>
          <div className={`search-filters-overlay ${searchPageFilterClass}`}>
            <Filters moveLeft={!this.state.showFilterBar} toggleFilterMoveLeftClass={this.toggleFilterMoveLeftClass} redrawMarkers={this.redrawMarkers}/>
          </div>
          
          {!this.state.loading ? 
            <div className='search-content'>
              <div className='search-top website'>
                <div className='public-profile-header'>
                  <img className='public-profile-image shadow' src={this.state.imageURL} alt='userImage'></img>
                  <div className='public-profile-name-box'>
                    <div className='public-profile-name'>
                      {this.state.fullName}

                      {followButton}
                    </div>
                    
                    <hr/>

                    {!this.state.starred
                    ? 
                      <div className='public-profile-subheader'>
                        <div className='public-profile-subheader-content'>
                          <div className='public-profile-subheader-content-title med-font'>
                            {this.state.numberOfPosts}
                          </div>
                          Suggestions
                        </div>
                        <div className='public-profile-subheader-content'>
                          <div className='public-profile-subheader-content-title med-font'>
                            {this.state.numberOfFollowers}
                          </div>
                          Followers
                        </div>
                        <div className='public-profile-subheader-content'>
                          <div className='public-profile-subheader-content-title med-font'>
                            {this.state.numberOfLikes}
                          </div>
                          Likes
                        </div>
                      </div>
                    : 
                      <div className='public-profile-subheader'>
                        <div className='public-profile-subheader-content'>
                          <div className='public-profile-subheader-content-title med-font'>
                            <i className="fas fa-star star-active"></i>
                          </div>
                          Suggestions
                        </div> 
                      </div>
                    }   
                  </div>
                </div>
              </div>

              <div className='search-header'>
                <div>Search Results: <span className='search-result-number'>Showing {this.props.filteredSearchResults.length} out of {this.props.searchResults.length}</span></div>
                <button className='search-sort-btn shadow' 
                  onClick={this.showSortingStyles}
                  onMouseLeave={this.hideSortingStyles}>Sort <i className="fas fa-sort"></i> : {this.state.sortStyle}</button>
                <div id='sort-styles' className='search-sort-styles'
                  onMouseEnter={this.showSortingStyles}
                  onMouseLeave={this.hideSortingStyles}>
                  <div className='search-sort-style' onClick={() => this.sortStuff('Recent')}>Recent</div>
                  <div className='search-sort-style' onClick={() => this.sortStuff('Likes')}>Likes</div>
                </div>
              </div>

              <div className='search-cards'>
                {this.props.filteredSearchResults.map(result => {
                  return(
                    <Card 
                      key={result.post_id} 
                      result={result}
                      showMap={true}
                      likeNumberUpdate={this.likeNumberUpdate}
                    />
                  )
                })}
              </div>
              {this.props.searchResults.length === 0 &&
                <div className='search-no-results'>
                  <p>Oh Oh!</p>
                  <br/>
                  <p>
                    Looks like {this.state.firstName} has not 
                    {!this.state.starred ?
                      ' posted ':
                      ' starred ' 
                    }
                    any suggestions. 
                    <i className="far fa-frown"></i>
                  </p>
                </div>
              }
            </div>:
            <div className='search-content'>
              <div className='search-content-loading'>
                <PropagateLoader
                  color={"#333335"}
                  />
              </div>
            </div>
          }
          

          <div className='search-map-box'>
            <Map/>
          </div>
        </div>

        
      </section>
    )
  }
}

const mapStateToProps = state => {
  return {
    map: state.mapReducer.map,
    place: state.mapReducer.place,
    latitude: state.mapReducer.latitude,
    longitude: state.mapReducer.longitude,
    markers: state.markerReducer.markers,
    searchResults: state.searchReducer.results,
    filteredSearchResults: state.searchReducer.filteredResults,
    filters: state.filterReducer.filters,
    userID: state.userReducer.user.userId,
    isConnected: state.userReducer.connected,
    userFollowees: state.userReducer.user.userFollowees,
    username: state.userReducer.user.username,
  };
};

const mapDispatchToProps = {
  setMarkers,
  setSearchResults,
  setFilteredSearchResults,
  followUser,
  unfollowUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicProfile));