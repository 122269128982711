import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import '../styles/scss/Following.scss';

class Following extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      following: [],
      loading: true,
      followingLength: -1
    };
  }
  componentDidMount(){
    axios.get('/auth/follow')
    .then(response => {
      this.setState({
        following: response.data.following,
        loading: false,
        followingLength: response.data.following.length,
      });
    })
    .catch(error => {
      console.log(error);
      this.setState({
        loading: false,
        followingLength: 0,
      })
    });
  };
  render() {
    return (
      <section className='following shadow'>
        <div className='following-title'>
          Following
          <hr className='following-hr'/>
        </div>

        {!this.state.loading ?
        <div className='following-content'>
          {this.state.following.map(followee => {
            return (
              <Link className='following-content-followee shadow' key={followee.user_id} to={`/user/${followee.username}`}>
                <img className='following-content-followee-image' src={followee.image_url} alt={`${followee.first_name} profile`}/>
                <div className='following-content-followee-name'>{followee.first_name} {followee.last_name}</div>
              </Link>
            )
          })}  
        </div>
        :
          <div className='following-content-loading'>
            <ScaleLoader
              color='#ff0497'
              height={50}
              width={10}
              radius={25}
            />
          </div>
        }
        
        {this.state.followingLength === 0 &&
          <div className='following-content following-content-none'>
            Not following anyone. <i className="far fa-frown"></i>
          </div> 
        }
        

      </section>
    )
  }
}

export default Following;