export const setPost = (post, preview) => {
  return {
    type: "SET_POST",
    post,
    preview
  };
};

export const clearPost = () => {
  return {
    type: "CLEAR_POST",
  };
};

export const goBack = () => {
  return {
    type: "GO_BACK",
  };
};

export const setPlaceAutoSuggest = (place, address, latitude, longitude, categories) => {
  return {
    type: "SET_PLACE_AUTO_SUGGEST",
    place, 
    address, 
    latitude, 
    longitude, 
    categories
  }
}
export const setPlaceNameManually = (place) => {
  return {
    type: "SET_PLACE_NAME_MANUALLY",
    place, 
  }
}
export const setPlaceAddressManually = (address, latitude, longitude) => {
  return {
    type: "SET_PLACE_ADDRESS_MANUALLY",
    address,
    latitude,
    longitude 
  }
}
export const addImage = (image, imageURL) => {
  return {
    type: "ADD_IMAGE",
    image,
    imageURL
  }
}
export const removeImage = (removeIndex) => {
  return {
    type: "REMOVE_IMAGE",
    removeIndex
  }
}
export const setSuggestion = (suggestion) => {
  return {
    type: "SET_SUGGESTION",
    suggestion
  }
}
export const setWebsiteURL = (websiteURL) => {
  return {
    type: "SET_WEBSITE_URL",
    websiteURL
  }
}
export const clearPlace = () => {
  return {
    type: "CLEAR_PLACE",
  }
}
