import React from 'react';
import '../styles/scss/Landing.scss';
import { connect } from 'react-redux';
import { addFilter, addFilters, removeFilter, clearFilters } from '../redux/actions/filterActions';
import About from './About';
import SearchBar from './SearchBar';
import Footer from './Footer';

class Landing extends React.Component{
  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll);
    // noticed bug on mobile where mountain background not positioned at the bottom -> potential fix below
    const balloon = document.getElementById('landing-balloon');
    if (window.innerWidth <= 700){
      balloon.style.bottom = '-100px';
    } else {
      balloon.style.bottom = '-175px';
    };
  };
  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  };
  handleScroll = () => {
    let scrolled = window.pageYOffset;
    let mountainInitialBottom = -175;
    if (window.innerWidth <= 700){
      mountainInitialBottom = -100
    };
    const mountain = document.getElementById('landing-mountain');
    const mountainPosition = mountainInitialBottom + (scrolled * .15);
    if (mountainPosition < 0){
      mountain.style.bottom = mountainPosition + 'px';
    };
  };
  setFilter = category => {
    let categoryId = `landing-${category}`;
    let currentFilters = this.props.filters;
    let subCategories = this.props.categories[category];
    this.clearFilters(currentFilters);

    if (category in currentFilters) {
      document.getElementById(categoryId).classList.remove('landing-category-active');
    } else {
      this.props.addFilters([category, ...subCategories]);
      document.getElementById(categoryId).classList.add('landing-category-active');
    };
  };

  clearFilters = (activeFilters) => {
    this.removeActiveClass(Object.keys(activeFilters));
    this.props.clearFilters();
  };
  removeActiveClass = (filtersActive) => {
    filtersActive.some(activeFilter => {
      if (activeFilter in this.props.categories){
        document.getElementById(`landing-${activeFilter}`).classList.remove('landing-category-active');
        return true;
      };
      return false;
    });
  };

  render(){
    return(
      <div>
        <div className='landing shadow-bottom'>
          <div className='landing-content'>
            <h1 className='landing-title'>
              Find Stuff 2 Do
            </h1>

            <div className='landing-search-bar'>
              <SearchBar searchBarButtonText='Find Me'/>      
            </div> 

            <div className='landing-categories'>
              <div id='landing-food' className='landing-category' onClick={() => this.setFilter('food')}>
                <i className="fas fa-utensils"></i> Food
              </div>
              <div id='landing-drinks' className='landing-category' onClick={() => this.setFilter('drinks')}>
                <i className="fas fa-coffee"></i> Drinks
              </div>
              <div id='landing-landmarks' className='landing-category' onClick={() => this.setFilter('landmarks')}>
                <i className="fas fa-mountain"></i> Landmarks
              </div>
              <div id='landing-shopping' className='landing-category' onClick={() => this.setFilter('shopping')}>
                <i className="fas fa-shopping-bag"></i> Shopping
              </div>
            </div>
          </div>
          <div className='landing-photo-credit'>
            Photo by  
            <a href='https://unsplash.com/@graciadharmaa' className='landing-photo-credit-links'>
              Gracia Dharma
            </a>on 
            <a href='https://unsplash.com/' className='landing-photo-credit-links'>
              Unsplash
            </a>
          </div>
          <div className='landing-balloon-horizontal-movement'>
            <img id='landing-balloon' className='landing-balloon-image' alt='balloon-bg' src='https://fun-stuff-2-do-bucket.s3.us-east-2.amazonaws.com/Fun+Stuff+2+Do/balloon.png'/>
          </div>
          
          <img id='landing-mountain' className='landing-mountain-image' alt='mountain-bg' src='https://fun-stuff-2-do-bucket.s3.us-east-2.amazonaws.com/Fun+Stuff+2+Do/mountain.png'/>
        </div>

        <About/>
        <Footer/>
      </div>
    )
  };
};

const mapStateToProps = state => {
  return {
    categories: state.filterReducer.categories,
    filters: state.filterReducer.filters,
  };
};

const mapDispatchToProps = {
  addFilter,
  addFilters,
  removeFilter,
  clearFilters,
}
export default connect(mapStateToProps, mapDispatchToProps)(Landing);