import React from 'react';
import { connect } from 'react-redux';
import { addImage, removeImage } from "../../redux/actions/postActions";

class PostStep2 extends React.Component{

  addImage = event => {
    Object.values(event.target.files).forEach(image => {
      let imageURL = URL.createObjectURL(image);
      this.props.addImage(image, imageURL);
    });
  };

  removeImage = (index) => {
    this.props.removeImage(index);
  }

  render(){
    return (
      <div className='post-form-steps'>
        <div id='step-2' className='post-form-step-header'>
          Step <span id='step-2-bg' className='post-form-step-number'>2</span>: <span className='post-form-step-description'>Add Image(s)</span>
        </div>

        <div className='post-form-section'>
          <div className='post-form-left-side text-right post-form-images-order-2'>
            <input id='file' type='file' accept='image/*' onChange={this.addImage} multiple/>
            <label htmlFor='file' className='post-file-button shadow'> <i className="fas fa-upload"></i> Add Image(s) </label>
          </div>
          
          <div className='post-form-right-side post-form-images-order-1'>
            <div className='post-form-images'>
              {this.props.imageURLs.map((url, index) => {
                return <div key={index} className="overlay"><img src={url} className='post-image' alt="images" onClick={() => this.removeImage(index)}/></div>
              })}
            </div>
            <i className="fas fa-exclamation-circle"></i> Click on Image to Delete 
          </div>
        </div>
      </div>

    )
  }
}

const mapStateToProps = state => {
  return {
    place: state.postReducer.place,
    images: state.postReducer.images,
    imageURLs: state.postReducer.imageURLs
  };
};

const mapDispatchToProps = {
  addImage,
  removeImage
}

export default connect(mapStateToProps, mapDispatchToProps)(PostStep2);