import React, { Component } from "react";
import { BrowserRouter, Route} from "react-router-dom";

import Landing from "../components/Landing";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import SearchPage from '../components/SearchPage';
import Place from '../components/Place';
import Login from '../components/Login';
import Post from '../components/Post';
import PostConfirmed from '../components/PostConfirmed';
import Account from '../components/Account';
import AccountDetails from '../components/account/AccountDetails';
import EditPosts from '../components/account/EditPosts';
import DeleteAccount from '../components/account/DeleteAccount';
import PublicProfile from '../components/PublicProfile';
import Following from '../components/Following';
import PrivacyPolicy from '../components/Legal/PrivacyPolicy';
import TermsOfService from '../components/Legal/TermsOfService';

class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Route component={Navbar}/>
        <Route exact path='/' component={Landing}></Route>
        <Route exact path='/search' component={SearchPage}></Route>
        <Route exact path='/search/:placeName/:placeID' component={Place}></Route>
        <Route exact path='/login' component={Login}></Route>
        <Route exact path='/post' component={Post}></Route>
        <Route exact path='/post/confirmed' component={PostConfirmed}></Route>
        <Route path='/account' component={Account}></Route>
        <Route exact path='/account/details' component={AccountDetails}></Route>
        <Route exact path='/account/posts' component={EditPosts}></Route>
        <Route exact path='/account/delete' component={DeleteAccount}></Route>
        <Route exact path='/user/:username' component={PublicProfile}></Route>
        <Route exact path='/user/:username/starred' component={PublicProfile}></Route>
        <Route exact path='/following' component={Following}></Route>
        <Route exact path='/privacypolicy'      component={PrivacyPolicy}></Route>
        <Route exact path='/termsofservice'  component={TermsOfService}></Route>
        <Route component={Footer}/>
      </BrowserRouter>
    );
  }
}

export default Routes;
