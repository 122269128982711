// import React
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
// import Redux
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
// import React Router
import Routes from './routes/Routes';
// import global styles
import './App.scss'

// import reducers
import mapReducer from "./redux/reducers/mapReducer";
import markerReducer from './redux/reducers/markerReducer';
import userReducer from './redux/reducers/userReducer';
import postReducer from './redux/reducers/postReducer';
import searchReducer from './redux/reducers/searchReducer';
import filterReducer from './redux/reducers/filterReducer';

// combine reducers
const rootReducer = combineReducers({
  mapReducer,
  markerReducer,
  userReducer,
  postReducer,
  searchReducer,
  filterReducer,
});

const store = createStore(rootReducer);


ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
