import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setPost } from '../redux/actions/userActions';
import { setSearchResultsLikeCount } from '../redux/actions/searchActions';
import axios from 'axios';
import '../styles/scss/Card.scss';

class Card extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      pictureIndex: 0,
      maxIndex: this.props.result.image_urls.length - 1
    }
  }
  componentDidMount(){
    if (this.props.result.post_id in this.props.userLikes){
      this.updateIconColor('like', true);
    } 
    if (this.props.result.post_id in this.props.userStars){
      this.updateIconColor('star', true);
    };
  };
  componentDidUpdate(prevProps){
    if (Object.keys(prevProps.userLikes).length !== Object.keys(this.props.userLikes).length){
      if (this.props.result.post_id in this.props.userLikes) {
        this.updateIconColor('like', true);
      }
      if (this.props.result.post_id in this.props.userStars) {
        this.updateIconColor('star', true);
      };
    };
  };
  liked = () => {
    axios.post('/auth/like', {
      postID: this.props.result.post_id
    })
    .then(response => {
      if (response.data.loggedIn){
        this.updateIconColor('like', response.data.likes);
        this.updateLikeIconCount(response.data.likes);
        if (this.props.likeNumberUpdate){   // -> public profile page, update like counter
          this.props.likeNumberUpdate(response.data.likes);
        }
      } else {
        this.props.history.push('/login');
      };
    })
    .catch(error => {
      console.log(error);
    });
  };

  starred = () => {
    axios.post('/auth/star', {
      postID: this.props.result.post_id
    })
    .then(response => {
      if (response.data.loggedIn){
        this.updateIconColor('star', response.data.stars)
      } else {
        this.props.history.push('/login');
      };
    })
    .catch(error => {
      console.log(error);
    });
  }

  flagged = () => {
    // console.log('flagged');
  }

  updateIconColor = (icon, bool) => {
    let idSelector = `${icon}-${this.props.result.post_id}`
    // if button isn't present -> ignore updating color
    if (!document.getElementById(idSelector)) return

    if (bool){
      document.getElementById(idSelector).classList.add(`${icon}-active`);
    } else {
      document.getElementById(idSelector).classList.remove(`${icon}-active`);
    };
  };
  updateLikeIconCount = (bool) => {
    if (bool){
      this.props.setSearchResultsLikeCount(this.props.result.post_id, 1);
    } else {
      this.props.setSearchResultsLikeCount(this.props.result.post_id, -1);
    };
  };

  updatePictureIndex = (index) => {
    const carousel = document.getElementById(`${this.props.result.post_id}-carousel`);
    let imageWidth = 198;
    // if mobile
    if (window.innerWidth < 700){
      imageWidth = 173;
    };

    switch (index){
      case 1: 
        if (this.state.pictureIndex + 1 <= this.state.maxIndex) {
          this.setState((state) => {
            return {
              pictureIndex: state.pictureIndex + 1
            }
          }, () => {
            carousel.scrollLeft += imageWidth;
          });
        } else {
          this.setState({
            pictureIndex: 0
          }, () => {
            carousel.scrollLeft = 0;
          });
        }
        break;
      case -1:
        if (this.state.pictureIndex - 1 >= 0) {
          this.setState((state) => {
            return {
              pictureIndex: state.pictureIndex - 1
            }
          }, () => {
            carousel.scrollLeft -= imageWidth;
          });
        } else {
          this.setState((state) => {
            return {
              pictureIndex: state.maxIndex
            }
          }, () => {
            carousel.scrollLeft = carousel.scrollWidth;
          });
        }
        break;
      default:
        console.log('oh oh')
    }
  }

  hideAllPopups = () => {
    Object.values(this.props.markers).forEach(marker => {
      if (marker.getPopup().isOpen())
        marker.togglePopup();
    });
  }
  
  showPopup = () => {
    if (this.props.showMap){
      this.hideAllPopups();

      let currentMarker = this.props.markers[this.props.result.post_id];
      if (!currentMarker.getPopup().isOpen())
        currentMarker.togglePopup();
    }
  }
  hidePopup = () => {
    if (this.props.showMap){
      let currentMarker = this.props.markers[this.props.result.post_id];
      if (currentMarker.getPopup().isOpen())
        currentMarker.togglePopup();
    };
  }
  centerMap = () => {
    this.props.map.flyTo({
      center: [this.props.result.longitude, this.props.result.latitude]
    })
  }
  readMore = () => {
    this.props.history.push(`/search/${this.props.result.place}/${this.props.result.post_id}`);
  }
  edit = () => {
    this.props.setPost(this.props.result, 'Edit');
  };
  delete = () => {
    this.props.setPost(this.props.result, 'Delete');
  }
  clickCard = () => {
    if (this.props.showMap) {
      this.readMore();
    } else {
      this.edit();
    }
  }
  loadAllOtherImages = () => {
    this.props.result.image_urls.forEach((image_url, index) => {
      if (index > 0){
        document.getElementById(`${image_url}-${index}`).src = image_url;
      }
    })
  }
  render(){
    return (
      <div className='card shadow' 
        onMouseEnter={() => this.showPopup()}
        onMouseLeave={() => this.hidePopup()}
        >
        <div className='card-img-box'>
          {this.props.result.user_id && 
            <Link to={`/user/${this.props.result.username}`}><img src={this.props.result.user_image_url} className='card-userImg' alt='creatorImage'/></Link>
          }
          <div id={`${this.props.result.post_id}-carousel`} className='carousel'>
            <img 
              id={`${this.props.result.image_urls[0]}-0`}  
              className='card-img' 
              src={this.props.result.image_urls[0]}
              loading='auto'
              alt={`${this.props.result.place}`}
              onClick={this.clickCard}
              onLoad={this.loadAllOtherImages}></img>
            {this.props.result.image_urls.map((image_url, index) => {
              if (index > 0)
                return <img 
                  key={index}
                  id={`${image_url}-${index}`}  
                  className='card-img' 
                  src='#'
                  loading='auto'
                  alt={`${this.props.result.place}`}></img>
              return false
            })}
          </div>

          { this.state.maxIndex ? 
            <div className="card-img-arrows">
              <button id='arrow-left' className='card-img-arrow-left' onClick={() => this.updatePictureIndex(-1)}>&#10094;</button>
              <button id='arrow-right' className='card-img-arrow-right' onClick={() => this.updatePictureIndex(1)}>&#10095;</button>
            </div>
            : ''
          }
        </div>

        <div className='card-title' onClick={this.clickCard}>
          {this.props.result.place}
        </div>
        <div className='card-body'>
          {this.props.result.suggestion}
        </div>
        { this.props.showMap === true ? 
          <div className='card-body-buttons'>
            <button className='card-body-button card-body-button-locate' onClick={() => this.centerMap()}>
              Locate
            </button>
            <button className='card-body-button card-body-button-readmore' onClick={() => this.readMore()}>
              Read More
            </button>
          </div> :
          <div className='card-body-buttons'>
            <button className='card-body-button card-body-button-edit' onClick={this.edit}>
              Edit
            </button>
          </div> 
        }

        {this.props.showMap === true ?
          <div className='card-footer'>
            <div className='card-footer-left' onClick={this.liked}>
              {this.props.result.count_likes} <i id={`like-${this.props.result.post_id}`} className="fas fa-thumbs-up"></i>
            </div>
            <i id={`star-${this.props.result.post_id}`} className="fas fa-star" onClick={this.starred}></i>
            <i className="fas fa-flag" onClick={this.flagged}></i>
          </div> :
          <div className='card-footer-delete'>
            <i className="fas fa-trash" onClick={this.delete}></i>
          </div>
        }
        
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    map: state.mapReducer.map,
    markers: state.markerReducer.markers,
    userLikes: state.userReducer.user.userLikes,
    userStars: state.userReducer.user.userStars,
  };
};

const mapDispatchToProps = {
  setPost,
  setSearchResultsLikeCount,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Card));