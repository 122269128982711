export const setSearchResults = (results) => {
  return {
    type: "SET_SEARCH_RESULTS",
    results
  };
};

export const setFilteredSearchResults = (filteredResults) => {
  return {
    type: "SET_FILTERED_SEARCH_RESULTS",
    filteredResults
  };
};

export const clearSearchResults = () => {
  return {
    type: "CLEAR_SEARCH_RESULTS",
  };
};

export const setSearchResultsLikeCount = (post_id, counter) => {
  return {
    type: "SET_SEARCH_RESULTS_LIKE_COUNT",
    post_id,
    counter,
  }
}