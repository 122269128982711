import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import isUrl from 'is-url';
import PropagateLoader from 'react-spinners/PropagateLoader';
import '../styles/scss/Post.scss';
import PostStep1 from './postSteps/PostStep1';
import PostStep2 from './postSteps/PostStep2';
import PostStep3 from './postSteps/PostStep3';
import PostStep4 from './postSteps/PostStep4';

class Post extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      stepNumber: 1,
      loading: false,
    }
  }

  componentDidMount(){
    this.renderStep();
  }

  updateStep = (value) => {
    if (value > 0 && this.checkForErrors()) {
      return;
    }

    let newStep = this.state.stepNumber + value;
    if (newStep >= 1 && newStep <= 4){
      this.setState({
        stepNumber: newStep
      }, () => this.renderStep());
    };
  }

  renderStep = () => {
    switch (this.state.stepNumber) {
      case 1:
        document.getElementById('post-step-1').classList.add('post-step-active');
        document.getElementById('post-step-2').classList.remove('post-step-active');
        break;
      case 2:
        document.getElementById('post-step-1').classList.remove('post-step-active');
        document.getElementById('post-step-2').classList.add('post-step-active');
        document.getElementById('post-step-3').classList.remove('post-step-active');
        break;
      case 3:
        document.getElementById('post-step-2').classList.remove('post-step-active');
        document.getElementById('post-step-3').classList.add('post-step-active');
        break;
      default: 
    }
  }

  checkForErrors = () => {
    let errorFound = false;

    switch(this.state.stepNumber){
      case 1:
        if (this.props.place === '' || this.props.address === '' ||
          this.props.latitude === '' || this.props.longitude === '') {
          errorFound = true;
          document.getElementById('step-1').style.color = 'darkred';
          document.getElementById('step-1-bg').style.background = 'darkred';
        } else {
          document.getElementById('step-1').style.color = 'darkgreen';
          document.getElementById('step-1-bg').style.background = 'darkgreen';
        }
        break;
      case 2:
        if (this.props.imageURLs.length < 1){
          errorFound = true;
          document.getElementById('step-2').style.color = 'darkred';
          document.getElementById('step-2-bg').style.background = 'darkred';
        } else {
          document.getElementById('step-2').style.color = 'darkgreen';
          document.getElementById('step-2-bg').style.background = 'darkgreen';
        }
        break;
      case 3:
        if (!isUrl(this.props.websiteURL) && this.props.websiteURL !== ""){
          errorFound = true;
          document.getElementById('website-url-invalid').style.display = 'block';
        } else {
          document.getElementById('website-url-invalid').style.display = 'none';
        }
        if (this.props.suggestion === ''){
          errorFound = true;
          document.getElementById('step-3').style.color = 'darkred';
          document.getElementById('step-3-bg').style.background = 'darkred';
        } else {
          document.getElementById('step-3').style.color = 'darkgreen';
          document.getElementById('step-3-bg').style.background = 'darkgreen';
        }
        break;
      default:
    }
    return errorFound;
  };

  submitPost = () => { 
    this.loading(true);
    this.getPresignedURL();
  };
  getPresignedURL = () => {
    axios.post('/image/url', {
      place: this.props.place, 
      length: this.props.imageURLs.length
    })
    .then(response => {
      // upload to s3
      this.uploadImages(response.data.preSignedURLs);
    })
    .catch(error => {
      console.log(error)
    });
  };
  uploadImages = (preSignedURLs) => {
    let options = {
      headers: {
        'Content-Type': 'image/*'
      }
    }
    const numberOfImages = preSignedURLs.length;
    let imagesUploaded = 0;
    let imageURLs = new Array(numberOfImages).fill(false);    
    
    for (let i = 0; i < numberOfImages; i++){
      axios.put(preSignedURLs[i], this.props.images[i], options)
      // eslint-disable-next-line 
      .then(response => {
        imageURLs[i] = preSignedURLs[i].split('?')[0];
        imagesUploaded += 1;
        let progressProgression = (imagesUploaded  / numberOfImages) * 100;
        this.updateProgressBar(progressProgression);

        if (imagesUploaded === numberOfImages){
          this.postContent(imageURLs);
        };
      })
      .catch(error => {
        console.log('error posting image');
        console.log(error);
      });
    };
  };
  postContent = (imageURLs) => {
    let payload = {
      place:      this.props.place,
      address:    this.props.address,
      latitude:   this.props.latitude,
      longitude:  this.props.longitude,
      categories: this.props.categories,
      imageURLs,
      suggestion: this.props.suggestion,
      website_url: this.props.websiteURL,
    }
    axios.post('/auth/post', payload)
    .then(response => {
      if (response.data && response.data.postId){
        this.props.history.push(`/post/confirmed?place_name=${this.props.place}&place_id=${response.data.postId}`);
      }
    })
    .catch(error => {
      console.log(error);
    });
  };

  loading = (isLoading) => {
    this.setState({
      loading: isLoading,
    });
  };
  updateProgressBar = (percentage) => {
    document.getElementById('meter-progress').style.width = `${percentage}%`;
  }

  render(){
    let stepComponent;

    switch (this.state.stepNumber) {
      case 1: 
        stepComponent = <PostStep1/>
        break;
      case 2: 
        stepComponent = <PostStep2/>
        break;
      case 3:
        stepComponent = <PostStep3/>
        break;
      case 4:
        stepComponent = <PostStep4
                          place       = {this.props.place}
                          address     = {this.props.address}
                          latitude    = {this.props.latitude}
                          longitude   = {this.props.longitude}
                          categories  = {this.props.categories}
                          imageURLs   = {this.props.imageURLs}
                          suggestion  = {this.props.suggestion}
                          websiteURL  = {this.props.websiteURL}
                        />
        break;
      default:
        console.log('Could not find component');
    }

    return (
      <div className='post shadow'>
        <div className='post-title post-form'>
          Post
          <hr className='post-form-hr'/>
        </div>

        { this.state.stepNumber < 4 ?
          <div>
            <div className='post-step-title'>Steps:</div>
            <div className='post-steps'>
              <div id='post-step-1'>
                1
              </div>
              <div id='post-step-2'>
                2
              </div>
              <div id='post-step-3'>
                3
              </div>
            </div> 
          </div>:
          <div className='post-step-title'>Preview:</div>}

       {stepComponent}

       {this.state.loading &&
          <div className='post-preview-loading'>
            <PropagateLoader
              color={"#333335"}
              />
            <div className="post-preview-loading-meter shadow">
              <div id='meter-progress' className='post-preview-loading-meter-progress'/>
            </div>
          </div>}

        <div className='post-form-btns'>
          { this.state.stepNumber > 1 ? 
            <button className='post-form-previous-btn' onClick={() => this.updateStep(-1)}>
              Previous
            </button> :
            ''}

          { this.state.stepNumber < 4 ?
            <button className='post-form-next-btn' onClick={() => this.updateStep(1)}>
              Next
            </button> :
            <button className='post-form-next-btn' onClick={this.submitPost}>
              Submit
            </button>}
        </div>      
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    preview: state.postReducer.preview,
    place: state.postReducer.place,
    address: state.postReducer.address, 
    latitude: state.postReducer.latitude, 
    longitude: state.postReducer.longitude, 
    categories: state.postReducer.categories,
    images: state.postReducer.images,
    imageURLs: state.postReducer.imageURLs,
    suggestion: state.postReducer.suggestion,
    websiteURL: state.postReducer.websiteURL,
  };
};

export default withRouter(connect(mapStateToProps)(Post));