const INITIAL_STATE = {
  markers: {}
}

const markerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_MARKERS": {
      return {
        markers: action.markers
      };
    }
    default:
      return state;
  }
};

export default markerReducer;
