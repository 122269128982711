import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import queryString from 'query-string';
import { setSearchLocation } from "../redux/actions/mapActions";
import { setMarkers } from "../redux/actions/markerActions";
import { setSearchResults, setFilteredSearchResults } from "../redux/actions/searchActions";
import PropagateLoader from 'react-spinners/PropagateLoader';
import mapboxgl from 'mapbox-gl';
import '../styles/scss/SearchPage.scss';
import Card from './Card';
import Filters from './Filters';
import Map from './Map';

class SearchPage extends React.Component{
  constructor(props){
    super(props);

    let showFilterBar = false
    if (window.innerWidth >= 1100)
      showFilterBar = true
    
    this.state = {
      showFilterBar,
      sortStyle: 'Recent',
      loading: true,
    };
  };

  componentDidMount(){
    const qs = queryString.parse(this.props.location.search);
    const lat = (qs.lat) ? parseFloat(qs.lat) : this.props.latitude;
    const lng = (qs.lng) ? parseFloat(qs.lng) : this.props.longitude;
    this.getSuggestions(lat, lng);
  };

  getSuggestions = (latitude, longitude) => {
    this.loading(true);
    axios.post('/search', {
      latitude,
      longitude
    })
    .then(response => {
      response.data.map(row => {
        if (row.image_urls !== null) {
          row.image_urls = Object.values(JSON.parse(row.image_urls));
        } else {
          row.image_urls = [];
        };
        if (row.categories !== null) {
          row.categories = row.categories.split(',');
        } else {
          row.categories = [];
        };
        return true;
      });

      
      this.props.setSearchResults(response.data);
      if (Object.keys(this.props.filters).length === 0){
        this.props.setFilteredSearchResults(response.data);
        this.redrawMarkers(response.data);
      } else if ('Other' in this.props.filters) {
        this.getFilteredResults(response.data, true);   
      } else {
        this.getFilteredResults(response.data, false);
      }
      this.loading(false);
    })
    .catch(error => {
      console.log(error);
    });
  };
  getFilteredResults = (payload, other) => {
    // 'other' refers to uncategorized posts
    let filteredResults = payload.reduce((newArray, currentResult) => {
      if (other && currentResult.categories.length === 0) {
        newArray.push(currentResult);
      } else {
        currentResult.categories.some(category => {
          if (category in this.props.filters) {
            return newArray.push(currentResult);
          }
          return false
        })
      }
      return newArray
    }, []);
    this.props.setFilteredSearchResults(filteredResults);
    this.redrawMarkers(filteredResults);
  };

  toggleFilterMoveLeftClass = () => {
    this.setState({
      showFilterBar: !this.state.showFilterBar
    });
  };
  showSortingStyles = () => {
    document.getElementById('sort-styles').style.display = 'block';
  }
  hideSortingStyles = () => {
    document.getElementById('sort-styles').style.display = 'none';
  }
  sortLikes = () => {
    return this.props.filteredSearchResults.sort((a, b) => (a.count_likes < b.count_likes) ? 1 :
      (a.count_likes === b.count_likes) ? ((a.place > b.place) ? 1 : -1) : -1);
  }
  sortDate = () => {
    return this.props.filteredSearchResults.sort((a, b) => (a.date_created < b.date_created) ? 1 : -1);
  }
  sortStuff = (style) => {
    this.hideSortingStyles();
    let sortedData;
    switch (style){
      case 'Recent':
        sortedData = this.sortDate();
        break;
      case 'Likes':
        sortedData = this.sortLikes();
        break;
      default:
        console.log('Couldnt sort');
    };
    this.setState({
      sortStyle: style
    });
    this.props.setFilteredSearchResults(sortedData);
    this.redrawMarkers(sortedData);
  }

  redrawMarkers = (places) => {
    this.removeAllMarkers();
    this.createNewMarkers(places);
  }
  removeAllMarkers = () => {
    Object.values(this.props.markers).forEach(marker => {
      marker.remove();
    })
  };
  createNewMarkers = (places) => {
    let markers = {};
    // reverse array so that the very first card overlaps the second card
    let placesReversed = places.slice().reverse();
    placesReversed.forEach(place => {
      const marker = new mapboxgl.Marker({
          color: '#126486',
          scale: 0.75
        })
        .setLngLat([place.longitude, place.latitude])
        .setPopup(new mapboxgl.Popup({
          // closeOnMove: true,
          closeButton: false,
          className: 'map-popup'
        }).setHTML(`
          <div>
            <img class='map-popup-img' src="${place.image_urls[0]}"/>
            <div class='map-popup-title'>${place.place}</div>
          </div>
        `)) // add popup
        .addTo(this.props.map);

      markers[place.post_id] = marker;
    });
    this.props.setMarkers(markers);
  };

  exampleCity = () => {
    let city = 'San Francisco';
    let latitude = 37.7648;
    let longitude = -122.463;

    this.props.map.flyTo({
      center: [longitude, latitude]
    });
    this.props.setSearchLocation(latitude, longitude, city);
    this.getSuggestions(latitude, longitude);
  };

  loading = (isLoading) => {
    this.setState({
      loading: isLoading
    });
  };

  render(){
    let searchPageFilterClass = this.state.showFilterBar ? '' : 'search-filters';
    return(
      <section className='search'>

        <div className='search-top mobile'>
          <h2 className='search-title'>
            Searching For Stuff 2 Do Near
          </h2>
          <h2 className='search-subtitle'>
            {this.props.place}
          </h2>
        </div>

          <div className='search-body'>
            <div className={`search-filters-overlay ${searchPageFilterClass}`}>
              <Filters moveLeft={!this.state.showFilterBar} toggleFilterMoveLeftClass={this.toggleFilterMoveLeftClass} redrawMarkers={this.redrawMarkers}/>
            </div>

            {!this.state.loading ? 
              <div className='search-content'>
                <div className='search-top website'>
                  <h2 className='search-title'>
                    Searching For Stuff 2 Do Near
                  </h2>
                  <h2 className='search-subtitle'>
                    {this.props.place}
                  </h2>
                </div>

                <div className='search-header'>
                  <div>Search Results: <span className='search-result-number'>Showing {this.props.filteredSearchResults.length} out of {this.props.searchResults.length}</span></div>
                  <div className='search-sort-box'>

                    <button className='search-sort-btn shadow' 
                      onClick={this.showSortingStyles}
                      onMouseLeave={this.hideSortingStyles}>Sort <i className="fas fa-sort"></i> : {this.state.sortStyle}

                      <div id='sort-styles' className='search-sort-styles'
                      onMouseEnter={this.showSortingStyles}
                      onMouseLeave={this.hideSortingStyles}>
                      <div className='search-sort-style' onClick={() => this.sortStuff('Recent')}>Recent</div>
                      <div className='search-sort-style' onClick={() => this.sortStuff('Likes')}>Likes</div>
                    </div>
                    </button>
                    
                  </div>
                </div>

                <div className='search-cards'>
                  {this.props.filteredSearchResults.map(result => {
                    return(
                      <Card 
                        key={result.post_id} 
                        result={result}
                        showMap={true}
                      />
                    )
                  })}
                </div>
                {this.props.searchResults.length === 0 &&
                  <div className='search-no-results'>
                    <p>Oh No!</p>
                    <br/>
                    <p>You've ventured into uncharted lands. <i className="far fa-frown"></i></p>
                    <p>Be the first to suggest a fun stuff 2 do here!</p>
                    <br/>
                    <p>Visit fun stuff 2 do</p>
                    <p>in <span className='search-no-results-example' onClick={this.exampleCity}>San Francisco</span> for examples. <i className="far fa-laugh-beam"></i></p>
                  </div>
                }
              </div>:
              <div className='search-content'>
                <div className='search-content-loading'>
                  <PropagateLoader
                    color={"#333335"}
                    />
                </div>
              </div>
            }
            
            <div className='search-map-box'>
              <Map getSuggestions={this.getSuggestions} showSearchButton={true}/>
            </div>
          </div>
      </section>
    )
  };
};

const mapStateToProps = state => {
  return {
    map: state.mapReducer.map,
    place: state.mapReducer.place,
    latitude: state.mapReducer.latitude,
    longitude: state.mapReducer.longitude,
    markers: state.markerReducer.markers,
    searchResults: state.searchReducer.results,
    filteredSearchResults: state.searchReducer.filteredResults,
    filters: state.filterReducer.filters,
  };
};

const mapDispatchToProps = {
  setMarkers,
  setSearchResults,
  setSearchLocation,
  setFilteredSearchResults,
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);