import React from 'react';
import { connect } from 'react-redux';
import { setUserLocation, setSearchLocation, setMap } from "../redux/actions/mapActions";
import { setMarkers } from "../redux/actions/markerActions";
import { withRouter } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import queryString from 'query-string';
import MapBoxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../styles/scss/Map.scss';

class Map extends React.Component{

  componentDidMount(){
    const qs = queryString.parse(this.props.location.search);
    const lat = (qs.lat) ? parseFloat(qs.lat) : this.props.latitude;
    const lng = (qs.lng) ? parseFloat(qs.lng) : this.props.longitude;

    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: 10,
      // maxZoom: 10,
    });
    this.props.setMap(map);

    // replace geocoder with new geocoder now that we have search location -> gives better results
    const Geocoder = this.initGeocoder();
    try {
      document.getElementById('geocoder').replaceChild(Geocoder.onAdd(map), document.getElementById('geocoder').childNodes[0]);
    } catch {
      document.getElementById('geocoder').appendChild(Geocoder.onAdd(map));
    }
    document.getElementById('geocoder').addEventListener('click', () => {Geocoder.clear();});

    Geocoder.on('result', (result) => {
      this.props.setSearchLocation(result.result.center[1], result.result.center[0], result.result.text);
      if (this.props.getSuggestions){
        this.props.getSuggestions(result.result.center[1], result.result.center[0]);
      }
      this.props.history.push(`/search?lat=${result.result.center[1]}&lng=${result.result.center[0]}`);
    });

  };

  initGeocoder = () => {
    return this.geocoder();
  }

  geocoder = () => {
    const Geocoder = new MapBoxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false,
      placeholder: 'Search Location',
      types: 'region,place,postcode,locality,neighborhood',
    });
    return Geocoder;
  }

  searchHere = () => {
    this.props.setSearchLocation(this.props.map.getCenter().lat, this.props.map.getCenter().lng, '');
    if (this.props.getSuggestions) {
      this.props.getSuggestions(this.props.map.getCenter().lat, this.props.map.getCenter().lng);
    }
    this.props.history.push(`/search?lat=${this.props.map.getCenter().lat}&lng=${this.props.map.getCenter().lng}`);
  };

  render(){
    return(
      <div>
        <div ref={el => this.mapContainer = el} className='search-map shadow'>
          { this.props.showSearchButton &&
            <button className='map-search-button' onClick={this.searchHere}>Search Here</button>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    longitude: state.mapReducer.longitude,
    latitude: state.mapReducer.latitude,
    place: state.mapReducer.place,
    markers: state.markerReducer.markers,
    map: state.mapReducer.map
  };
};

const mapDispatchToProps = {
  setUserLocation,
  setSearchLocation,
  setMap,
  setMarkers
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Map));