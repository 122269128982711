import React from 'react';
import { connect } from 'react-redux';
import { setPost, setSuggestion, setWebsiteURL } from "../../redux/actions/postActions";

class PostStep3 extends React.Component{

  updateSuggestion = (event) => {
    this.props.setSuggestion(event.target.value);
  };
  updateWebsiteURL = (event) => {
    this.props.setWebsiteURL(event.target.value)
  };

  render(){
    return (
      <div className="post-form-steps">
        <div id='step-3' className='post-form-step-header'>
          Step <span id='step-3-bg' className='post-form-step-number'>3</span>: <span className='post-form-step-description'>Add Details</span>
        </div>
        
        <div className='post-form-section post-form-section-center'>
          <div className='post-form-website'>
            <div className='post-form-auto-suggest-label-website'>(Optional) Add Website:</div>
            <span className='post-form-website-name'>
              <i className="fas fa-external-link-alt"></i> Website
            </span>
            <input 
              className='post-form-website-edit'
              type="text"
              placeholder="https://www.example.com"
              value={this.props.websiteURL}
              onChange={this.updateWebsiteURL}>
            </input>
            <div id='website-url-invalid' className='post-form-website-invalid'> * URL is invalid</div>
          </div>
          <div className='post-form-right-side'>
            <div className='post-form-auto-suggest-label'>Add Suggestion:</div>
            <textarea className="post-form-textarea" value={this.props.suggestion} onChange={this.updateSuggestion} placeholder="Please share what you would recommend people to try or do at this location :)"/>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    suggestion:   state.postReducer.suggestion,
    websiteURL:   state.postReducer.websiteURL
  };
};

const mapDispatchToProps = {
  setPost,
  setSuggestion,
  setWebsiteURL,
}

export default connect(mapStateToProps, mapDispatchToProps)(PostStep3);