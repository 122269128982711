import React from 'react';
import { connect } from 'react-redux';
import { setAllPosts, setPost, clearPost } from "../../redux/actions/userActions";
import Card from '../Card';
import ConfirmationPage from './ConfirmationPage';
import axios from 'axios';

class EditPost extends React.Component{
  componentDidMount(){
    window.scrollTo(0, 0);
    axios.get('/auth/posts')
    .then(response => {
      response.data.map(row => {
        if (row.image_urls !== null) {
          row.image_urls = Object.values(JSON.parse(row.image_urls));
        } else {
          row.image_urls = [];
        };
        if (row.categories !== null) {
          row.categories = row.categories.split(',');
        } else {
          row.categories = [];
        };
        return true;
      });

      this.props.setAllPosts(response.data);
    })
    .catch(error => {
      console.log(error);
    })
  };
  
  render(){
    return (
      <div className='account-edit-posts'>

        {this.props.post === false ? 
          <div className='account-posts-results'>
            {this.props.allPosts.map(result => {
              return <Card key={result.post_id} result={result} showMap={false}/>
            })}
          </div>:
          <ConfirmationPage/>
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    allPosts: state.userReducer.posts.allPostings,
    post: state.userReducer.posts.post,
    action: state.userReducer.posts.action,
  };
};

const mapDispatchToProps = {
  setAllPosts,
  setPost,
  clearPost
};
export default connect(mapStateToProps, mapDispatchToProps)(EditPost);