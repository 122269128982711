import React from 'react';
import { Helmet } from 'react-helmet';
import '../../styles/scss/Legal.scss';

class PrivacyPolicy extends React.Component {
  componentDidMount(){
    window.scrollTo(0,0);
  };
  render(){
    return (
      <div className='legal shadow'>
        <Helmet>
          <title>Privacy Policy - Fun Stuff 2 Do</title>
          <meta name='description' content='Fun Stuff 2 Do</span> (“we” or “us” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.FunStuff2Do.com, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.'/>
        </Helmet>
        
        <p className='legal-title'>Privacy Policy</p>
        <p>Last Updated: <span className='legal-highlight'>October 30th, 2020</span></p>
        <hr className='legal-hr'/>

        <div className='legal-content'>
          <p className='legal-content-header med-font'>Introduction</p>
          <p className='legal-content-paragraph'>
            <span className='legal-highlight'>Fun Stuff 2 Do</span> (“we” or “us” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <span className='legal-highlight'>www.FunStuff2Do.com</span>, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
          </p>
          <p className='legal-content-paragraph'>
            We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the “Last Updated” date of this Privacy Policy. Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on the Site, and you waive the right to receive specific notice of each such change or modification.
          </p>
          <p className='legal-content-paragraph'>
            You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Site after the date such revised Privacy Policy is posted.
          </p>
          <p className='legal-content-paragraph'>
            This Privacy Policy was generated by Termly’s Privacy Policy Generator.
          </p>

          <p className='legal-content-header med-font'>What Information is Collected?</p>
          <p className='legal-content-paragraph'>We may collect information about you in a variety of ways. The information we may collect on the Site includes:</p>
          <div className='legal-content'>
            <p className='legal-content-header med-font'>Personal Data</p>
            <p className='legal-content-paragraph'>Personally identifiable information, such as your first name, last name, email address and profile picture that you voluntarily give to us <span className='legal-highlight'>when you register with the Site</span>. You are under no obligation to provide us with personal information of any kind, however your refusal to do so may prevent you from using certain features of the Site.</p>

            <p className='legal-content-header med-font'>Non-Persistant Data</p>
            <p className='legal-content-paragraph'>Geolocation information can also be acquired when the (“Find Me”, or “<i className="fas fa-map-marker-alt"></i>”) button is clicked; however, your current location is not persistently saved. This can be made apparent when the page refreshes.</p>

            <p className='legal-content-header med-font'>Data From Social Networks</p>
            <p className='legal-content-paragraph'>If logged in using <span className='legal-highlight'>Facebook</span>, the Site may have access to your <span className='legal-highlight'>Facebook</span> basic account information. This includes but not limited to your first name, last name, email, and profile picture URL.</p>
            <p className='legal-content-paragraph'>If logged in using <span className='legal-highlight'>Google</span>, the Site may have access to your <span className='legal-highlight'>Google</span> basic account information. This includes but not limited to your first name, last name, email, and profile picture URL.</p>
          </div>
        
          <p className='legal-content-header med-font'>Use Of Your Information</p>
          <p className='legal-content-paragraph'>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:</p>
          <ul id='bullet-points-color' className='legal-content'>
            <li className='legal-content'>
              Create and manage your account.
            </li>
            <li className='legal-content'>
              Generate a personal profile about you to make future visits to the Site more personalized.
            </li>
            <li className='legal-content'>
              Increase the efficiency and operation of the Site.
            </li>
            <li className='legal-content'>
              Monitor and analyze usage and trends to improve your experience with the Site.
            </li>
            <li className='legal-content'>
              Offer new services, and/or recommendations to you.
            </li>
            <li className='legal-content'>
              Perform other business activities as needed.
            </li>
            <li className='legal-content'>
              Resolve disputes and troubleshoot problems.
            </li>
          </ul>

          <p className='legal-content-header med-font'>Disclosure Of Your Information</p>
          <p className='legal-content-paragraph'>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
          <div className='legal-content'>
            <p className='legal-content-header med-font'>By Law Or To Protect Rights</p>
            <p className='legal-content-paragraph'>If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection.</p>

            <p className='legal-content-header med-font'>Third-Party Service Providers</p>
            <p className='legal-content-paragraph'>We may share your information with third parties that perform services for us or on our behalf, including data analysis.</p>

            <p className='legal-content-header med-font'>Interaction With Site</p>
            <p className='legal-content-paragraph'>Since the Site is aimed to help everyone, all content posted is made publicly viewable. Your account is also made publicly viewable as well as all your starred post.</p>
          </div>

          <p className='legal-content-header med-font'>Cookies And Web Beacons</p>
          <p className='legal-content-paragraph'>We may use cookies to help customize the Site and improve your experience. When you access the Site, your personal information is not collected through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the Site.</p>

          <p className='legal-content-header med-font'>Website Analytics</p>
          <p className='legal-content-paragraph'>We may also partner with selected third-party vendors <span className='legal-highlight'>Google Analytics</span>, to allow tracking technologies and remarketing services on the Site through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Site, determine the popularity of certain content and better understand online activity. By accessing the Site, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors. However, if you do not want any information to be collected and used by tracking technologies, you can visit the third-party vendor or the <span className='legal-highlight'>Network Advertising Initiative Opt-Out Tool</span> or <span className='legal-highlight'>Digital Advertising Alliance Opt-Out Tool</span>.</p>
          <p className='legal-content-paragraph'>You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.</p>

          <p className='legal-content-header med-font'>Third-Party Websites</p>
          <p className='legal-content-paragraph'>The Site may contain links to third-party websites that are not affiliated with us and we do not share any information with those third-party websites. Once you have used these links to leave the Site, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Site.</p>
          
          <p className='legal-content-header med-font'>Security Of Your Information</p>
          <p className='legal-content-paragraph'>While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.</p>

          <p className='legal-content-header med-font'>Policy For Children</p>
          <p className='legal-content-paragraph'>We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below.</p>

          <p className='legal-content-header med-font'>Controls For Do-Not-Track Features</p>
          <p className='legal-content-paragraph'>Most web browsers and some mobile operating systems include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy. Most web browsers and some mobile operating systems include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. If you set the DNT signal on your browser, we will respond to such DNT browser signals.</p>

          <p className='legal-content-header med-font'>Options Regarding Your Information</p>
          <p className='legal-content-paragraph'>You may at any time review or change the information in your account or terminate your account by:</p>
          <ul id='bullet-points-color' className='legal-content'>
            <li className='legal-content'>
              Logging into your account settings and updating your account.
            </li>
            <li className='legal-content'>
              Contacting us using the contact information provided below.
            </li>
          </ul>
          <p className='legal-content-paragraph'>Upon your request to terminate your account, we will delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>

          <p className='legal-content-header med-font'>California Privacy Rights</p>
          <p className='legal-content-paragraph'>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>
          <p className='legal-content-paragraph'>If you are under 18 years of age, reside in California, and have a registered account with the Site, you have the right to request removal of unwanted data that you publicly post on the Site. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Site, but please be aware that the data may not be completely or comprehensively removed from our systems.</p>
        
          <p className='legal-content-header med-font'>Contact Us</p>
          <a id='contact-email' className='legal-content-paragraph legal-highlight' href='mailto:ContactFunStuff2Do@gmail.com'>ContactFunStuff2Do@gmail.com</a>
        </div>
      </div>
    )
  };
};

export default PrivacyPolicy;