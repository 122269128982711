export const setCategories = (categories) => {
  return {
    type: "SET_CATEGORIES",
    categories
  };
};

export const addFilter = (filter) => {
  return {
    type: "ADD_FILTER",
    filter
  };
};

export const addFilters = (filters) => {
  return {
    type: "ADD_FILTERS",
    filters
  };
};

export const removeFilter = (filter) => {
  return {
    type: "REMOVE_FILTER",
    filter
  };
};

export const clearFilters = () => {
  return {
    type: "CLEAR_FILTERS",
  };
};
