export const loginUser = (userId, firstName, lastName, imageURL, email, userSince, userLikes, userStars, userFollowees, connected, username) => {
  return {
    type: "LOGIN_USER",
    userId,
    firstName,
    lastName,
    imageURL,
    email,
    userSince,
    userLikes,
    userStars,
    userFollowees,
    connected,
    username,
  };
};

export const logoutUser = (connected) => {
  return {
    type: 'LOGOUT_USER',
    connected
  }
}

export const setAllPosts = (allPosts) => {
  return {
    type: 'SET_ALL_POSTS',
    allPosts
  }
}

export const setPost = (post, action) => {
  return {
    type: 'SET_POST',
    post,
    action
  }
}

export const clearPost = () => {
  return {
    type: 'CLEAR_POST',
  }
}

export const followUser = (userID) => {
  return {
    type: 'FOLLOW_USER',
    userID,
  }
}

export const unfollowUser = (userID) => {
  return {
    type: 'UNFOLLOW_USER',
    userID,
  }
}