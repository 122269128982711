const INITIAL_STATE = {
  user: {
    userId: '',
    firstName: '',
    lastName: '',
    imageURL: '',
    email: '',
    userSince: '',
    userLikes: {},
    userStars: {},
    userFollowees: {},
    username: '',
  },
  posts: {
    allPostings: [],
    post: false,
    action: false
  },
  connected: false,
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LOGIN_USER": {
      return {
        ...state,
        user: {
          userId: action.userId,
          firstName: action.firstName,
          lastName: action.lastName,
          imageURL: action.imageURL,
          email: action.email,
          userSince: action.userSince,
          userLikes: action.userLikes,
          userStars: action.userStars,
          userFollowees: action.userFollowees,
          username: action.username,
        },
        connected: action.connected
      };
    }
    case "LOGOUT_USER": {
      return {
        ...state,
        user: {
          userId: '',
          firstName: '',
          lastName: '',
          imageURL: '',
          email: '',
          userSince: '',
          userLikes: {},
          userStars: {},
          userFollowees: {},
          username: ''
        },
        connected: action.connected
      };
    }
    case "SET_ALL_POSTS": {
      return {
        ...state,
        posts: {
          ...state.posts,
          allPostings: action.allPosts
        }
      };
    }
    case "SET_POST": {
      return {
        ...state,
        posts: {
          ...state.posts,
          post: action.post,
          action: action.action
        }
      };
    }
    case "CLEAR_POST": {
      return {
        ...state,
        posts: {
          ...state.posts,
          post: false,
          action: false,
        }
      }
    }
    case "FOLLOW_USER": {
      return {
        ...state,
        user: {
          ...state.user,
          userFollowees: {
            ...state.user.userFollowees,
            [action.userID]: true,
          }
        }
      }
    }
    case "UNFOLLOW_USER": {
      return {
        ...state,
        user: {
          ...state.user,
          userFollowees: Object.keys(state.user.userFollowees).reduce((obj, key) => {
            if (key !== action.userID){
              return obj[key] = true
            } return obj
          }, {})
        }
      }
    }
    default:
      return state;
  }
};

export default userReducer;
