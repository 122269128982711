import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { clearPost } from '../redux/actions/postActions';

class postConfirmed extends React.Component{
  constructor(props){
    super(props);

    let parseURL = queryString.parse(this.props.location.search);
    this.state = {
      placeId:    parseURL.place_id,
      placeName:  parseURL.place_name,
    };
  };
  componentDidMount(){
    this.props.clearPost();
  };

  render(){
    return (
      <div className='post-confirmed shadow'>
        <i className="fas fa-check checkmark"></i>
        <div className='post-confirmed-title'>
          Thank You!
        </div>
        <hr className='post-confirmed-hr'/>

        <div className='post-confirmed-body'>
          Your post has been successfully uploaded!
          <br className='post-confirmed-breaks'/>
          We truly appreciate you taking the time to help build Fun Stuff 2 Do into a better and stronger community!
          <br className='post-confirmed-breaks'/>
          To view your suggestion, click the button below.
        </div>

        <Link to={`/search/${this.state.placeName}/${this.state.placeId}`}><button className='post-confirmed-btn'>View</button></Link>
      </div>
    )
  }
}

const mapDispatchToProps = {
  clearPost
}
export default connect(null, mapDispatchToProps)(postConfirmed);