import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../../redux/actions/userActions';
import '../../styles/scss/Account.scss';

class DeleteAccount extends React.Component{
  constructor(props){
    super(props);
    
    this.state = {
      continue: false,
    };
  }
  continue = () => {
    this.setState({
      continue: true
    });
  };
  cancel = () => {
    this.setState({
      continue: false
    });
  };
  delete = () => {
    axios.delete('/auth/profile')
    .then(response => {
      if (response.data.loggedIn){
        if (response.data.deleted){
          this.props.logoutUser(false);
          this.props.history.push('/');
        } 
      } else {
        this.props.history.push('/login');
      };
    })
    .catch(error => {
      console.log(error)
    });
  };
  render(){
    return (
      <div className='delete shadow'>
        <div className='delete-header'>
          Delete Account
          <hr className='delete-header-hr'/>
        </div>

        <div className='delete-content'>
          {!this.state.continue ? 
            <div className='delete-content-option'>
              <p>
              Account deletion is a <span className='delete-uppercase'>permanent</span> and <span className='delete-uppercase'>irreversible</span> action...
              </p>
              <br/>
              <p>
              Would you still like to continue?
              </p>
              <button className='delete-btn-continue' onClick={this.continue}>Continue</button>
            </div>:
            <div className='delete-content-option'>
              <div className='delete-content-option-warning'>
                <div className='delete-content-option-warning-header med-font'>
                  The following will be deleted:
                </div>
                <ul id='delete-content-bullet-points-color'>
                  <li className='delete-content-option-warning-list'>
                    All account details will be deleted from our database.
                  </li>
                  <li className='delete-content-option-warning-list'>
                    Public profile will be deleted, not hidden.
                  </li>
                  <li className='delete-content-option-warning-list'>
                    All content posted will be removed.
                  </li>
                </ul>
              </div>
              
              <div className='delete-content-option-btns'>
                <button className='delete-btn-cancel' onClick={this.cancel}>Cancel</button>
                <button className='delete-btn-delete' onClick={this.delete}>Delete</button>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user
  };
};

const mapDispatchToProps = {
  logoutUser,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteAccount));