import React from 'react';
import { withRouter, Link } from 'react-router-dom';

const MAPBOX_API_KEY = process.env.REACT_APP_MODE === 'development' ? 
  process.env.REACT_APP_MAPBOX_PRIVATE_API_KEY:
  process.env.REACT_APP_MAPBOX_PUBLIC_API_KEY

class postStep4 extends React.Component{

  render(){
    let mapURL = `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/pin-s+126486(${this.props.longitude},${this.props.latitude})/${this.props.longitude},${this.props.latitude},14,0/1000x300@2x?access_token=${MAPBOX_API_KEY}`;
    return (
      <div>   
        <div className='post-preview-location'>
          <img className='post-preview-place-map' src={mapURL} alt='map'/> 
          <div className='post-preview-place'>{this.props.place}</div> 
        </div>

        <div className='post-preview-categories'>
          {this.props.categories.map(category => {
            return <span key={category} className='post-preview-category'>{category}</span>
          })}
        </div>
        
        {this.props.websiteURL && 
          <div className='post-preview-website'>
            <Link 
            to={this.props.websiteURL} 
            target='_blank'
            onClick={(event) => {event.preventDefault(); window.open(this.props.websiteURL);}}>
              <button className='post-preview-button-website'>
                <i className="fas fa-external-link-alt"></i> Website
              </button>
            </Link>
          </div>
        }
        

        <div className='post-preview-image-box'>
          <div className='post-preview-images'>
            {this.props.imageURLs.map(image => {
              return <img className='post-preview-image' key={image} src={image} alt='place'/>
            })}
          </div>
        </div>

        <div className='post-preview-content'>
          <div className='post-preview-suggestion'>
            <div className='post-preview-suggestion-title'>Suggestion</div>
            <div className='post-preview-suggestion-box'>
              {this.props.suggestion}
            </div>
          </div>
        </div>

        <div className='post-preview-warning'>
          <i className="fas fa-exclamation"></i> 
          Note: Once submitted, place name and address can NOT be changed or edited.
        </div>
      </div>
    )
  }
}


export default withRouter(postStep4);