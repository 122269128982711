import React from 'react';
import { connect } from 'react-redux';
import '../styles/scss/Filters.scss';
import { addFilter, addFilters, removeFilter, clearFilters } from '../redux/actions/filterActions';
import { setFilteredSearchResults } from "../redux/actions/searchActions";


class Filters extends React.Component{
  constructor(props) {
    super(props)

    this.state = {
      filterSize: 0,
    }
  }
  componentDidMount(){
    
    Object.keys(this.props.filters).some(category => {
      if (category in this.props.categories){
        document.getElementById(category).classList.add('category-active');
        return true;
      }
      return false;
    });
  };
  componentDidUpdate(){
    let filterLength = Object.keys(this.props.filters).length;
    if ( filterLength !== this.state.filterSize){
      this.setState({
        filterSize: filterLength
      }, () => this.filterSearchResults());  
    }
  }
  componentWillUnmount(){
    this.removeActiveClass(Object.keys(this.props.filters));
    this.props.clearFilters();
  }

  filterSubCategory = (category) => {
    let currentFilters = this.props.filters;
    this.clearFilters(currentFilters);

    if (Object.keys(currentFilters).length <= 1){
      if (category in currentFilters){
        document.getElementById(category).classList.remove('category-active');
      } else {
        this.props.addFilter(category);
        document.getElementById(category).classList.add('category-active');
      };
    } else {
      this.props.addFilter(category);
      document.getElementById(category).classList.add('category-active');
    };
  };
  filterMainCategory = (category, subCategories) => {
    let currentFilters = this.props.filters;
    this.clearFilters(currentFilters);

    if (category in currentFilters){
      document.getElementById(category).classList.remove('category-active');
    } else {
      this.props.addFilters([category, ...subCategories]);
      document.getElementById(category).classList.add('category-active');
    };
  };

  clearFilters = (activeFilters) => {
    this.setState({
      filterSize: 0
    });
    this.removeActiveClass(Object.keys(activeFilters));
    this.props.clearFilters();
  };
  removeActiveClass = (filtersActive) => {
    filtersActive.forEach(activeFilter => {
      document.getElementById(activeFilter).classList.remove('category-active');
    });
  };

  filterSearchResults = () => {
    // if no filters -> set filtered results to all search results
    if (this.state.filterSize === 0){
      this.props.setFilteredSearchResults(this.props.searchResults);
      this.props.redrawMarkers(this.props.searchResults);
    }
    else if ('Other' in this.props.filters) {
      this.filteringSearchResults(true);
    }
    else {
      this.filteringSearchResults(false);
    }
  };
  filteringSearchResults = (other) => {
    // 'other' refers to uncategorized posts
    let filteredResults = this.props.searchResults.reduce((newArray, currentResult) => {
      if (other && currentResult.categories.length === 0) {
        newArray.push(currentResult);
      } else {
        currentResult.categories.some(category => {
          if (category in this.props.filters) {
            return newArray.push(currentResult);
          }
          return false
        })
      }
      return newArray
    }, []);
    this.props.setFilteredSearchResults(filteredResults);
    this.props.redrawMarkers(filteredResults);
  }

  render(){
    let filterMoveLeftClass = this.props.moveLeft ? 'filters-move-left' : '';
    let filterArrowRotateClass = this.props.moveLeft ? '' : 'filter-arrow-rotate';
    return(
      <section className={`filters ${filterMoveLeftClass}`}>
        <div className='filters-title-bar shadow' onClick={this.props.toggleFilterMoveLeftClass}>
          <div className='filters-title'>
            Filters
          </div>
          <div className={`filters-arrow`}>
            <i className={`fas fa-angle-double-right ${filterArrowRotateClass}`}></i>
          </div>
        </div>

        <div className='filters-categories shadow-bottom'>
          <div id='food' className='filters-main-category' onClick={() => this.filterMainCategory('food', this.props.categories.food)}>
            Food
          </div>
          <div className='filters-sub-categories'>
            {this.props.categories.food.map(category => {
              return <div key={category} id={category} className='filters-sub-category' onClick={() => this.filterSubCategory(category)}>{category}</div>
            })}
          </div>

          <div id='drinks' className='filters-main-category' onClick={() => this.filterMainCategory('drinks', this.props.categories.drinks)}>
            Drinks
          </div>
          <div className='filters-sub-categories'>
            {this.props.categories.drinks.map(category => {
              return <div key={category} id={category} className='filters-sub-category' onClick={() => this.filterSubCategory(category)}>{category}</div>
            })}
          </div>

          <div id='landmarks' className='filters-main-category' onClick={() => this.filterMainCategory('landmarks', this.props.categories.landmarks)}>
            Landmarks
          </div>
          <div className='filters-sub-categories'>
            {this.props.categories.landmarks.map(category => {
              return <div key={category} id={category} className='filters-sub-category' onClick={() => this.filterSubCategory(category)}>{category}</div>
            })}
          </div>

          <div id='shopping' className='filters-main-category' onClick={() => this.filterMainCategory('shopping', this.props.categories.shopping)}>
            Shopping
          </div>
          <div className='filters-sub-categories'>
            {this.props.categories.shopping.map(category => {
              return <div key={category} id={category} className='filters-sub-category' onClick={() => this.filterSubCategory(category)}>{category}</div>
            })}
          </div>

          {this.props.other.map(category => {
            return <div key={category} id={category} className='filters-main-category' onClick={() => this.filterSubCategory(category)}>{category}</div>
          })}
          <div id='Other' className='filters-main-category' onClick={() => this.filterSubCategory('Other')}>Other</div>
          
          {/* <button className='filters-clear-btn' onClick={this.clearFilters}>Clear</button> */}
        </div>
      </section>
    )
  };
};

const mapStateToProps = state => {
  return {
    categories: state.filterReducer.categories,
    other: state.filterReducer.other,
    filters: state.filterReducer.filters,
    searchResults: state.searchReducer.results,
    filteredSearchResults: state.searchReducer.filteredResults,
  };
};
const mapDispatchToProps = {
  addFilter,
  addFilters,
  removeFilter,
  clearFilters,
  setFilteredSearchResults,
}
export default connect(mapStateToProps, mapDispatchToProps)(Filters);