const INITIAL_STATE = {
  results: [],
  filteredResults: [],
}

const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_SEARCH_RESULTS": {
      return {
        ...state,
        results: action.results,
      };
    }
    case "SET_FILTERED_SEARCH_RESULTS":{
      return {
        ...state,
        filteredResults: action.filteredResults,
      };
    }
    case "CLEAR_SEARCH_RESULTS": {
      return {
        results: [],
        filteredResults: [],
      };
    }
    case "SET_SEARCH_RESULTS_LIKE_COUNT": {
      return {
        ...state,
        filteredResults: state.filteredResults.map(result => {
          if (result.post_id === action.post_id){
            return {
              ...result,
              count_likes: result.count_likes += action.counter
            }
          }
          return result
        })
      };
    }
    default:
      return state;
  }
};

export default searchReducer;
