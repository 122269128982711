import React from 'react';
import moment from 'moment';
import axios from 'axios';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { connect } from 'react-redux';
import { loginUser } from '../../redux/actions/userActions';

class AccountDetails extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      isEditing: false,
      image: '',
      imageURL: "",
      imageUpdated: false,
      loading: false,
      usernameOld: '',
      username: '',
      usernameError: false,
    };

  };
  componentDidMount(){
    if (this.state.imageURL === ""){
      this.setState({
        ...this.state,
        imageURL: this.props.user.imageURL,
        username: this.props.user.username,
      });
    };
  };
  // componentDidUpdate(){
  //   console.log(this.props.user)
  //   if (this.state.imageURL === ""){
  //     console.log(this.props.user.imageURL)
  //     this.setState({
  //       ...this.state,
  //       imageURL: this.props.user.imageURL,
  //     });
  //   };
  // };
  isEditing = (bool) => {
    this.setState({
      ...this.state,
      isEditing: bool
    });
    this.styleEditingPage(bool);
  };
  styleEditingPage = (bool) =>{
    let accountImage = document.getElementById('account-img').classList;

    if (bool){
      accountImage.add('account-img-editing');
      this.setState(prevState =>{ return {
        ...prevState, 
        usernameOld: prevState.username,
        }
      });  
    } else {
      accountImage.remove('account-img-editing');
      this.setState(prevState =>{ return {
        ...prevState, 
        username: prevState.usernameOld,
        }
      }); 
    };
  };
  reset = () => {
    this.setState({
      ...this.state,
      image: '',
      imageURL: this.props.user.imageURL,
      isEditing: false,
      imageUpdated: false,
      loading: false,
      username: this.props.user.username,
    });
    this.styleEditingPage(false);
  };
  save = () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    if (this.state.username !== this.state.usernameOld){
      // username can't be empty
      if (this.state.username.length === 0){
        this.setState({
          ...this.state,
          loading: false,
        });
        console.log('username cant be empty');
      } else {
       this.postUsername(this.state.username);
      }
    }
    else if (this.state.imageUpdated){
      this.getPresignedURL();
    } else {
      this.setState({
        ...this.state,
        loading: false,
        isEditing: false,
      });
    };
  };
  postUsername = (username) => {
    axios.put('/auth/profile/username', {
      username,
    })
    .then(response => {
      if (response.data){
        this.setState({
          ...this.state,
          username,
          usernameError: false,
        });
        if (this.state.imageUpdated){
          this.getPresignedURL();
        } else {
          this.reLogin();
        };
      } else {
        this.setState({
          ...this.state,
          loading: false,
          usernameError: true,
        });
      };
    });
  };
  getPresignedURL = () => {
    axios.post('/image/url', {
      place: `${this.props.user.firstName} ${this.props.user.lastName}`,
      length: 1
    })
    .then(response => {
      // upload to s3
      this.uploadImages(response.data.preSignedURLs[0]);
    })
    .catch(error => {
      console.log(error)
    });
  };
  uploadImages = (preSignedURL) => {
    let options = {
      headers: {
        'Content-Type': 'image/*'
      }
    }
    
    axios.put(preSignedURL, this.state.image, options)
    .then(response => {
      let imageURL = preSignedURL.split('?')[0];
      this.postContent(imageURL);
    })
    .catch(error => {
      console.log('error posting image');
      console.log(error);
    });
  };
  postContent = (imageURL) => {
    axios.put('/auth/profile', {
      imageURL,
    })
    .then(response => {
      if (response.data){
        this.setState({
          ...this.state,
          imageURL,
        });
        this.reLogin();
      } else {
        this.reset();
      }
    })
    .catch(error => {
      console.log(error);
    });
  };
  reLogin = () => {
    this.setState({
      ...this.state, 
      loading: false,
      isEditing: false,
    });
    this.props.loginUser(
      this.props.user.userId,
      this.props.user.firstName,
      this.props.user.lastName,
      this.state.imageURL,
      this.props.user.email,
      this.props.user.userSince,
      this.props.user.userLikes,
      this.props.user.userStars,
      this.props.user.userFollowees,
      true,
      this.state.username,
    );
    // axios.get('/auth/login')
    // .then(response => {
    //   let userData = response.data
    //   if(userData){
    //     let user_likes = {};
    //     let user_stars = {};

    //     if(userData.user_likes) {
    //       user_likes = userData.user_likes.split(',').reduce((key, value) => {
    //           key[value] = true;
    //           return key;
    //         }, {});
    //     }
    //     if (userData.user_stars){
    //       user_stars = userData.user_stars.split(',').reduce((key, value) => {
    //           key[value] = true;
    //           return key;
    //         }, {});
    //     }
    //     console.log(userData);
    //     this.props.loginUser(
    //       userData.user_id, 
    //       userData.first_name, 
    //       userData.last_name, 
    //       userData.image_url, 
    //       userData.email, 
    //       userData.user_since.split('T')[0], 
    //       user_likes,
    //       user_stars,
    //       true,
    //       userData.username);
    //     this.reset();
    //   };
    // })
    // .catch(error => {
    //   console.log(error)
    // });
  }
  changeImage = (event) => {
    let image = event.target.files[0];
    let imageURL = URL.createObjectURL(image);
    this.setState({
      ...this.state,
      image,
      imageURL,
      imageUpdated: true,
    })
  }
  changeUsername = (event) => {
    let value = event.target.value;
    if (value.length <= 36){
      this.setState({
        username: value.toLowerCase(),
      });
    };    
  };
  render(){
    return (
      <div className='account-details shadow'>
        <div className='account-details-header'>
          Account Details
          <hr className='account-details-hr'/>
        </div>

        <div className='account-details-content'>
          <div className='account-details-left-side'>
            Full Name:
          </div>
          <div className='account-details-right-side'>
            {this.props.user.firstName} {this.props.user.lastName}
          </div>
        </div>
        <div className='account-details-content'>
          <div className='account-details-left-side'>
            Email:
          </div>
            <div className = 'account-details-right-side'> {this.props.user.email} </div>
        </div>
        <div className='account-details-content'>
          <div className='account-details-left-side'>
            User Since:
          </div>
            <div className = 'account-details-right-side'> {moment(this.props.user.userSince).format('MM/DD/YYYY')} </div>
        </div>
        <div className='account-details-content'>
          <div className='account-details-left-side'>
            Username:
          </div>
            {!this.state.isEditing ?
              <div id='username' className = 'account-details-right-side'> {this.state.username} </div>:
              <input type='text' value={this.state.username} onChange={this.changeUsername} id='username' className = 'account-details-right-side account-img-editing'></input>
            }
        </div>
        <div className='account-details-content'>
          <div id='usernameError' className='account-details-left-side account-details-no-border'>{this.state.usernameError && 'Already Taken'}</div>
            <div className = 'account-details-right-side account-details-no-border'> /user/{this.state.username} </div>
        </div>
        
        <div className='account-details-content'>
          <div className='account-details-left-side'>
            {!this.state.isEditing ?
            'Profile Image:':
            <div className='account-detail-img-btn'>
              <input id='file' type='file' accept='image/*' onChange={this.changeImage}/>
              <label htmlFor='file' className=''>Change Image</label>
            </div>
            }
          </div>
          <div className='account-details-right-side account-img-box'>
            <img id="account-img" className='account-img' src={this.state.imageURL} alt="account-profile-pic"/>
          </div>
        </div>

        {this.state.loading &&
          <div className='account-details-loading'>
            <PropagateLoader
              color={"#333335"}
              />
          </div>
        }

        <div className='account-detail-btn-box'>
          {!this.state.isEditing ? 
            <button className='account-details-edit-btn' onClick={() => this.isEditing(true)}>Edit</button> :
            <div className='account-details-cancelSave'>
              <button className='account-details-cancel-btn shadow' onClick={() => this.reset()}>Cancel</button>
              <button className='account-details-save-btn' onClick={this.save}>Save</button>
            </div>
          }    
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user
  };
};
const mapDispatchToProps = {
  loginUser,
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);